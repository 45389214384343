import moment from "moment";
import React, { useState } from "react";
import { downloadAllEvidenceViewAPI, downloadEvidenceViewAPI } from "../../apiConfig/apiCall";
import BasicTable from "./BasicTable";
import { Button } from "reactstrap";

const EvidenceTable = ({ evidenceData }) => {
    const data = evidenceData;

    const isViewable = (filePath) => {
        const viewableExtensions = ["pdf", "jpg", "jpeg", "png", "txt", "csv"];
        const fileExtension = filePath.split(".").pop().toLowerCase();
        return viewableExtensions.includes(fileExtension);
    };

    // Logic for check all and download
    const [selectedFilePaths, setSelectedFilePaths] = useState([]);
    const [selectAll, setSelectAll] = useState(false);

    const handleRowCheckboxChange = (filePath, isChecked) => {
        setSelectedFilePaths((prev) => {
            if (isChecked) {
                return [...prev, filePath];
            }
            return prev.filter((path) => path !== filePath);
        });
    };

    const handleHeaderCheckboxChange = (isChecked) => {
        setSelectAll(isChecked);
        if (isChecked) {
            const allFilePaths = data.map((item) => item.filePath);
            setSelectedFilePaths(allFilePaths);
        } else {
            setSelectedFilePaths([]);
        }
    };


    const columns = [
        {
            header: (
                <input
                    type="checkbox"
                    checked={selectAll}
                    onChange={(e) => handleHeaderCheckboxChange(e.target.checked)}
                />
            ),
            accessorKey: "checkbox",
            cell: ({ row }) => {
                const filePath = row.original.filePath;
                return (
                    <input
                        type="checkbox"
                        checked={selectedFilePaths.includes(filePath)}
                        onChange={(e) => handleRowCheckboxChange(filePath, e.target.checked)}
                    />
                );
            },
            enableSorting: false,
        },
        {
            header: "File Name",
            accessorKey: "filePath",
            cell: ({ row }) => {
                const filePath = row.original.filePath;
                const fileName = filePath.substring(filePath.lastIndexOf("/") + 1);
                return <span>{fileName}</span>;
            },
        },
        {
            header: "Entry Date",
            accessorKey: "creationTime",
            cell: ({ row }) => (
                <span>{moment(row.original.creationTime).format("DD MMM YYYY, hh:mm A")}</span>
            ),
        },
        {
            header: "Modified Date",
            accessorKey: "lastModifiedTime",
            cell: ({ row }) => (
                <span>{moment(row.original.lastModifiedTime).format("DD MMM YYYY, hh:mm A")}</span>
            ),
        },
        {
            header: "Access Date",
            accessorKey: "lastAccessTime",
            cell: ({ row }) => (
                <span>{moment(row.original.lastAccessTime).format("DD MMM YYYY, hh:mm A")}</span>
            ),
        },
        {
            header: "Folder Name",
            accessorKey: "parentFolderName",
        },
        {
            header: "Actions",
            accessorKey: "actions",
            cell: ({ row }) => {
                const filePath = row.original.filePath;
                return (
                    <div className="d-flex gap-2 justify-content-start">
                        {isViewable(filePath) && (
                            <button
                                className="btn bg-info-subtle btn-sm text-info d-flex justify-content-center align-items-center"
                                title="View"
                                onClick={() => handleView(row.original)}
                            >
                                <i className="bx bx-show fs-5"></i>
                            </button>
                        )}
                        <button
                            className="btn bg-success-subtle btn-sm text-success d-flex justify-content-center align-items-center"
                            title="Download"
                            onClick={() => handleDownload(row.original)}
                        >
                            <i className="bx bxs-download fs-5"></i>
                        </button>
                    </div>
                );
            },
            enableSorting: false,
        },
    ];

    const handleView = async (data) => {
        const pid = data.pid;
        const filename = data.filePath;

        try {
            const filePath = filename;
            const encodedFilePath = encodeURIComponent(filePath);
            const apiResponse = await downloadEvidenceViewAPI(pid, encodedFilePath, "blob");

            if (apiResponse.status === 200) {
                // Create a blob URL to open the file in a new tab
                const blob = new Blob([apiResponse.data], { type: apiResponse.headers['content-type'] });
                const url = window.URL.createObjectURL(blob);

                // Open the file in a new tab
                window.open(url, "_blank");

                // Optionally revoke the blob URL after a delay
                setTimeout(() => window.URL.revokeObjectURL(url), 1000);
            }
        } catch (error) {
            console.error("Error while opening file:", error);
        }
    };

    const handleDownload = async (data) => {
        const pid = data.pid;
        const filename = data.filePath;
        try {
            const filePath = filename;
            const fileNameFormatDownload = filename.split(/[/\\]/).pop();
            const encodedFilePath = encodeURIComponent(filePath);
            const apiResponse = await downloadEvidenceViewAPI(pid, encodedFilePath, "blob");
            if (apiResponse.status === 200) {
                const blob = new Blob([apiResponse.data]);
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", fileNameFormatDownload);
                document.body.appendChild(link);
                link.click();
                window.URL.revokeObjectURL(url);
                link.remove();
            }
        } catch (error) {
            console.log("Error while downloading file:", error);
        }
    };

    const handleDownloadAllEvidence = async (data) => {
        const pid = data[0]?.pid;
        const filenames = selectedFilePaths.join(',');
        try {
            const apiResponse = await downloadAllEvidenceViewAPI(pid, filenames, "blob");
            if (apiResponse.status === 200) {
                const blob = new Blob([apiResponse.data], { type: 'application/zip' });
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `evidences.zip`);
                document.body.appendChild(link);
                link.click();
                window.URL.revokeObjectURL(url);
                link.remove();
            }
        } catch (error) {
            console.log("Error while downloading file:", error);
        }
    };

    return (
        <>
            <Button color="primary" onClick={() => handleDownloadAllEvidence(data)} disabled={selectedFilePaths.length === 0}>Download</Button>
            <BasicTable columns={columns} data={data} customPageSize={10} />
        </>
    );
};

export default EvidenceTable;