import { Button, Col, Form, Input, Label, Modal, ModalBody, ModalHeader, Nav, NavItem, NavLink, Row, Spinner, TabContent, Table, TabPane, Tooltip } from 'reactstrap';
import classnames from "classnames";
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import Cookies from 'js-cookie';
import { getAllUser, getCCFControlsApi, getProjectTaskWithCCF, mapUserWithCCFApi } from '../../apiConfig/apiCall';

export default function MapUserModal({ mapUserCCFModal, toggleMapUserCCFModal, pid }) {

    // State For Handle Forms
    const [submittingForm, setSubmittingForm] = useState(false);

    const [users, setUsers] = useState([]);
    const [ccfList, setCcfList] = useState([]);
    const [ccfDepartments, setCcfDepartments] = useState([]);

    const [activeArrowTab, setActiveArrowTab] = useState(4);
    const [passedarrowSteps, setPassedarrowSteps] = useState([1]);
    const [selectedUser, setSelectedUser] = useState(null);
    const [selectedCCFs, setSelectedCCFs] = useState([]);
    const [summaryData, setSummaryData] = useState({ user: null, ccfIds: [] });

    const [searchTerm, setSearchTerm] = useState('');
    const [departmentFilter, setDepartmentFilter] = useState('');

    function toggleArrowTab(tab) {
        if (tab === 5 && !selectedUser) {
            toast.error('Please select a user before proceeding.');
            return;
        }
        if (tab === 6 && selectedCCFs.length === 0) {
            toast.error('Please select at least one CCF before proceeding.');
            return;
        }

        if (activeArrowTab !== tab) {
            const modifiedSteps = [...passedarrowSteps, tab];
            if (tab >= 4 && tab <= 7) {
                setActiveArrowTab(tab);
                setPassedarrowSteps(modifiedSteps);

                if (tab === 6) {
                    setSummaryData({
                        user: selectedUser,
                        ccfIds: selectedCCFs.map(ccf => ccf.id),
                    });
                }
            }
        }
    }

    const handleUserSelection = (user) => {
        setSelectedUser(user);
        const assignedCCFs = ccfList.filter(ccf => ccf.user === user.ssoId);
        setSelectedCCFs(assignedCCFs);
    };

    const handleCCFSelection = (data) => {
        setSelectedCCFs(prevSelected => {
            if (prevSelected.some(selected => selected.id === data.id)) {
                return prevSelected.filter(ccf => ccf.id !== data.id);
            } else {
                return [...prevSelected, data];
            }
        });
    };

    const handleSearch = (e) => {
        setSearchTerm(e.target.value.toLowerCase());
    };

    const handleFilterChange = (e) => {
        setDepartmentFilter(e.target.value);
    };

    const handleSelectAllCCFs = (e) => {
        if (e.target.checked) {
            setSelectedCCFs(ccfList);
        } else {
            setSelectedCCFs([]);
        }
    };

    const filteredCCFList = ccfList.filter(item =>
        (item.controlReference.toLowerCase().includes(searchTerm) ||
            item.certificationVersionCCFPoints.department.toLowerCase().includes(searchTerm) ||
            item.certificationVersionCCFPoints.controlWording.toLowerCase().includes(searchTerm)) &&
        (departmentFilter === '' || item.certificationVersionCCFPoints.department === departmentFilter)
    );

    const handleSubmit = async () => {
        try {
            const data = {
                ssoid: selectedUser.ssoId,
                pid: pid,
                raIDList: summaryData.ccfIds,
            }
            setSubmittingForm(true);
            const apiResponse = await mapUserWithCCFApi(data);
            if (apiResponse.status === 200) {
                toggleMapUserCCFModal();
                toast.success(apiResponse.data);
            }
        } catch (error) {
            console.error('Error sending data:', error);
            toast.error('An error occurred while map user');
        } finally {
            setSubmittingForm(false);
        }
    };

    // Get All Users List Logic Start
    const [userRole, setUserRole] = useState("CLIENT_USER");

    const CLIENT_ID = Cookies.get('CLIENT_ID');

    const getUsers = async (userRole, CLIENT_ID) => {
        try {
            const apiresponse = await getAllUser(userRole, CLIENT_ID);
            if (apiresponse.status === 200) {
                setUsers(apiresponse.data);
            }
        } catch (error) {
            console.log(error);
        }
    }

    // Get CCF List
    const getProjectTaskDetailsCompliance = async (CLIENT_ID) => {
        try {
            const apiResponse = await getProjectTaskWithCCF(CLIENT_ID);
            if (apiResponse.status === 200) {
                setCcfList(apiResponse.data.requirementApproach);
            }
        } catch (error) {
            console.log(error);
        }
    }

    // Get CCF Departments
    const getCCFControls = async () => {
        try {
            const apiResponse = await getCCFControlsApi();
            if (apiResponse.status === 200) {
                setCcfDepartments(apiResponse.data.Department);
            }
        } catch (error) {
            console.log(error);
        }
    }

    // For verify existing user mapped
    const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
    const handleCheckboxChange = (e) => {
        setIsCheckboxChecked(e.target.checked);
    };

    // Control Wording Tooltip
    const [controlReferenceTooltipOpen, setControlReferenceTooltipOpen] = useState(null);
    const toggleControlReferenceTooltip = (index) => {
        setControlReferenceTooltipOpen(prevIndex => prevIndex === index ? null : index);
    };


    useEffect(() => {
        getUsers(userRole, CLIENT_ID);
        getProjectTaskDetailsCompliance(CLIENT_ID);
        getCCFControls();
    }, []);

    return (
        <div>
            <Modal size='lg' isOpen={mapUserCCFModal} toggle={toggleMapUserCCFModal} className='map-user-ccf-modal'>
                <ModalHeader toggle={toggleMapUserCCFModal}>Map User CCF</ModalHeader>
                <ModalBody>
                    <Form className="form-steps">
                        <div className="step-arrow-nav mb-4">
                            <Nav className="nav-pills custom-nav nav-justified" role="tablist">
                                <NavItem>
                                    <NavLink
                                        href="#"
                                        className={classnames({ active: activeArrowTab === 4 })}
                                        onClick={() => toggleArrowTab(4)}
                                    >
                                        Users
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        href="#"
                                        className={classnames({ active: activeArrowTab === 5 })}
                                        onClick={() => toggleArrowTab(5)}
                                    >
                                        Requirements
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        href="#"
                                        className={classnames({ active: activeArrowTab === 6 })}
                                        onClick={() => toggleArrowTab(6)}
                                    >
                                        Finish
                                    </NavLink>
                                </NavItem>
                            </Nav>
                        </div>

                        <TabContent activeTab={activeArrowTab}>
                            <TabPane tabId={4}>
                                <h5>Select any user for mapping</h5>
                                <Table>
                                    <thead>
                                        <tr>
                                            <th>Select</th>
                                            <th>Name (Username)</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {users.map((user, index) => (
                                            <tr key={index}>
                                                <td>
                                                    <Input
                                                        type="radio"
                                                        name="userSelect"
                                                        onChange={() => handleUserSelection(user)}
                                                        checked={selectedUser?.id === user.id}
                                                    />
                                                </td>
                                                <td>{user.firstName} {user.lastName} ({user.ssoId})</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                                <div className="d-flex align-items-start gap-3 mt-4">
                                    <button
                                        type="button"
                                        className="btn btn-success btn-label right ms-auto"
                                        onClick={() => toggleArrowTab(activeArrowTab + 1)}
                                    >
                                        <i className="ri-arrow-right-line label-icon align-middle fs-16 ms-2"></i>
                                        Next
                                    </button>
                                </div>
                            </TabPane>

                            <TabPane tabId={5}>
                                <Row>
                                    <Col lg={4}>
                                        <div>
                                            <h5>Select CCF from the list</h5>
                                            <Input id="selectAllCCF" name="check" type="checkbox" onChange={handleSelectAllCCFs} checked={selectedCCFs.length === ccfList.length} />
                                            <Label check for="selectAllCCF" className='ms-2'>Select All CCF </Label>
                                        </div>
                                    </Col>

                                    <Col lg={4}>
                                        <Input type="text" placeholder="Search by CCF name" onChange={handleSearch} value={searchTerm} />
                                    </Col>

                                    <Col lg={4}>
                                        <Input type="select" onChange={handleFilterChange} value={departmentFilter}>
                                            <option value="">All</option>
                                            {
                                                ccfDepartments.map((options, index) => (
                                                    <option value={options} key={index}>{options}</option>
                                                ))
                                            }
                                        </Input>
                                    </Col>
                                </Row>

                                <div style={{ height: '400px', overflowX: 'hidden', overflowY: 'scroll' }}>
                                    <Table>
                                        <thead style={{ position: 'sticky', top: '0', background: '#fff' }}>
                                            <tr>
                                                <th>Select</th>
                                                <th>CCF</th>
                                                <th>Department</th>
                                                <th>Assigned User</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                filteredCCFList.length > 0 ? (
                                                    filteredCCFList.map((data, index) => (
                                                        <tr key={index}>
                                                            <td>
                                                                <Input
                                                                    type="checkbox"
                                                                    onChange={() => handleCCFSelection(data)}
                                                                    checked={selectedCCFs.some(selected => selected.id === data.id)}
                                                                />
                                                            </td>
                                                            <td id={`controlReferenceTooltipTarget-${index}`}>{data.controlReference}</td>
                                                            <td>{data.certificationVersionCCFPoints.department}</td>
                                                            <td>{data.user}</td>

                                                            {/* Tooltip outside the <tr> */}
                                                            <Tooltip
                                                                placement="top"
                                                                isOpen={controlReferenceTooltipOpen === index}
                                                                target={`controlReferenceTooltipTarget-${index}`}
                                                                toggle={() => toggleControlReferenceTooltip(index)}
                                                            >
                                                                {data.certificationVersionCCFPoints.controlWording}
                                                            </Tooltip>
                                                        </tr>
                                                    ))
                                                ) : (
                                                    <tr>
                                                        <td colSpan="4" className="text-center">No Data Available</td>
                                                    </tr>
                                                )
                                            }
                                        </tbody>

                                    </Table>
                                </div>

                                <div className='pt-3'>
                                    <h6 className='fw-bold'>Selected CCF:</h6>
                                    {selectedCCFs.length > 0 ? (
                                        <ul className='row mb-0'>
                                            {selectedCCFs.map((ccf, index) => (
                                                <li key={index} className='col-lg-2'>
                                                    {ccf.controlReference}
                                                </li>
                                            ))}
                                        </ul>
                                    ) : (
                                        <p>No CCF selected yet.</p>
                                    )}
                                </div>


                                <div className="d-flex align-items-start gap-3 mt-4">
                                    <button
                                        type="button"
                                        className="btn btn-light btn-label previestab"
                                        onClick={() => {
                                            toggleArrowTab(activeArrowTab - 1);
                                        }}
                                    >
                                        <i className="ri-arrow-left-line label-icon align-middle fs-16 me-2"></i>{" "}
                                        Previous
                                    </button>
                                    <button
                                        type="button"
                                        className="btn btn-success btn-label right ms-auto"
                                        onClick={() => toggleArrowTab(activeArrowTab + 1)}
                                    >
                                        <i className="ri-arrow-right-line label-icon align-middle fs-16 ms-2"></i>
                                        Next
                                    </button>
                                </div>
                            </TabPane>

                            <TabPane tabId={6}>
                                <h5 className='text-center'>CCF mapping preview for {selectedUser ? selectedUser.ssoId : ''}</h5>
                                <Table className="text-center">
                                    <thead>
                                        <tr>
                                            <th>ID</th>
                                            <th>CCF</th>
                                            <th>Department</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            selectedCCFs.map((ccf, index) => (
                                                <tr key={index}>
                                                    <td>{ccf.id}</td>
                                                    <td>{ccf.controlReference}</td>
                                                    <td>{ccf.certificationVersionCCFPoints.department}</td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </Table>
                                <Row className="mt-4 align-items-center">
                                    <Col lg={3}>
                                        <button type="button" className="btn btn-light btn-label previestab" onClick={() => { toggleArrowTab(activeArrowTab - 1); }} >
                                            <i className="ri-arrow-left-line label-icon align-middle fs-16 me-2"></i>{" "}
                                            Previous
                                        </button>
                                    </Col>

                                    <Col lg={7}>
                                        <div>
                                            <Input id="exampleCheck" className='me-2' name="check" type="checkbox" onChange={handleCheckboxChange} />
                                            <Label check for="exampleCheck" > Select to overwrite existing records. </Label>
                                        </div>
                                    </Col>

                                    <Col lg={2}>
                                        {isCheckboxChecked && (
                                            submittingForm ? (
                                                <Button color="primary" className="me-2" type="button" disabled>
                                                    <Spinner size="sm"> Loading... </Spinner>
                                                </Button>
                                            ) : (
                                                <Button
                                                    color="primary"
                                                    type="submit"
                                                    className="me-2"
                                                    disabled={submittingForm}
                                                    onClick={handleSubmit}
                                                >
                                                    Update
                                                </Button>
                                            )
                                        )}
                                    </Col>
                                </Row>
                            </TabPane>
                        </TabContent>
                    </Form>
                </ModalBody>
            </Modal>


        </div >
    );
}