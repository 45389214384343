import React, { useEffect, useRef, useState } from 'react'
import { Alert, Button, Col, DropdownItem, DropdownMenu, DropdownToggle, Form, FormGroup, Input, ListGroup, ListGroupItem, Modal, ModalBody, Nav, NavItem, NavLink, Offcanvas, OffcanvasBody, OffcanvasHeader, Row, Spinner, TabContent, TabPane, UncontrolledDropdown } from 'reactstrap';
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import classnames from "classnames";
import { map } from "lodash";
import Cookies from 'js-cookie';
import { downloadAllEvidenceAPI, freezEvidenceAPI, getCCFStatusAPI, updateCCFStatusAPI } from '../../../apiConfig/apiCall';
import { toast } from 'react-toastify';
export default function ActivityCanvas({
    isRight,
    toggleRightCanvas,
    ccfNameTitle,
    topBorderTab,
    uploadEvidence,
    uploadFileLoading,
    handleFileChange,
    evidenceFile,
    activityCommentsTab,
    activeTab,
    referencesData,
    messages,
    currentUser,
    handleSendMessage,
    inputMessage,
    handleInputChange,
    topBordertoggle,
    toggle,
    deleteEvidence,
    downloadEvidence,
    idForDeleteFile,
    pid,
    projectManager,
    ccfStatusList,
    ccfID
}) {


    const role = Cookies.get('role');
    const userID = Cookies.get('user');

    const [checkedStates, setCheckedStates] = useState({});
    const handleCheckboxClick = async (id, pid, file) => {
        try {
            // setIsChecked(userID === file.status);
            const data = { id, pid, filename: file }

            const apiResponse = await freezEvidenceAPI(data);
            if (apiResponse.status === 200) {
                toast.success(apiResponse.data);
                setCheckedStates((prevStates) => ({
                    ...prevStates,
                    [file]: !prevStates[file],
                }));
            }
        } catch (error) {
            console.log(error.message);
        }
    };

    const statusOptions = ["PENDING", "COMPLETED", "REJECTED", "PROGRESSING"];
    const [status, setStatus] = useState(
        ccfStatusList.reduce((acc, item) => {
            const title = Object.keys(item)[0];
            acc[title] = item[title].status;
            return acc;
        }, {})
    );

    const handleStatusChange = async (title, newStatus) => {
        setStatus(prevStatus => ({
            ...prevStatus,
            [title]: newStatus
        }));

        const data = {
            id: idForDeleteFile,
            pid,
            status: newStatus,
            certificationName: title
        }

        const apiResponse = await updateCCFStatusAPI(data);
        if (apiResponse.status === 200) {
            toast.success(apiResponse.data);
        }
    };


    useEffect(() => {
        const initialState = evidenceFile.reduce((acc, file) => {
            acc[file.file] = file.status === userID;
            return acc;
        }, {});
        setCheckedStates(initialState);
    }, [evidenceFile, userID]);


    const [downloadEvidenceModal, setDownloadEvidenceModal] = useState(false);

    const downloadEvidenceModalPopup = () => setDownloadEvidenceModal(!downloadEvidenceModal);

    const downloadEvidenceOfOneCCF = async (ccfName) => {

        downloadEvidenceModalPopup();

        const apiResponse = await downloadAllEvidenceAPI(pid, ccfName, 'blob');

        if (apiResponse.status === 200) {
            setDownloadEvidenceModal(false);
            const blob = new Blob([apiResponse.data], { type: 'application/zip' });
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${ccfName || 'evidence'}.zip`);
            document.body.appendChild(link);
            link.click();
            window.URL.revokeObjectURL(url);
            link.remove();
        }

    }

    return (
        <>
            <Offcanvas direction="end" isOpen={isRight} toggle={toggleRightCanvas} id="offcanvasExample">
                <OffcanvasHeader toggle={toggleRightCanvas} id="offcanvasExampleLabel" className="border-bottom py-2" >
                    <div className="fs-13 mb-0">{ccfNameTitle} Acitivity</div>
                </OffcanvasHeader>
                <OffcanvasBody className="p-0 hideScrollbar">
                    <Nav tabs className="nav nav-tabs nav-justified nav-border-top nav-border-top-success mt-3 mb-3">
                        <NavItem>
                            <NavLink style={{ cursor: "pointer", padding: '.2rem', fontSize: '.75rem' }} className={classnames({ active: topBorderTab === "1", })} onClick={() => { topBordertoggle("1"); }} >
                                <i className="ri-home-5-line align-middle me-1"></i> Evidence
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink style={{ cursor: "pointer", padding: '.2rem', fontSize: '.75rem' }} className={classnames({ active: topBorderTab === "2", })} onClick={() => { topBordertoggle("2"); }} >
                                <i className="ri-user-line me-1 align-middle me-1"></i> Activities
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink style={{ cursor: "pointer", padding: '.2rem', fontSize: '.75rem' }} className={classnames({ active: topBorderTab === "3", })} onClick={() => { topBordertoggle("3"); }} >
                                <i className="ri-question-answer-line align-middle me-1"></i> References
                            </NavLink>
                        </NavItem>
                        {
                            role === "AUDITOR" && (
                                <NavItem>
                                    <NavLink
                                        style={{ cursor: "pointer", padding: '.2rem', fontSize: '.75rem' }}
                                        className={classnames({ active: topBorderTab === "4" })}
                                        onClick={() => { topBordertoggle("4"); }}
                                    >
                                        <i className="ri-edit-fill me-1"></i> Edit Status
                                    </NavLink>
                                </NavItem>
                            )
                        }
                    </Nav>

                    <TabContent activeTab={topBorderTab} className="text-muted px-3">

                        <TabPane tabId="1" id="nav-border-justified-home">
                            <h6>Evidence</h6>

                            <div className="d-flex align-items-center mb-2 cursor-pointer" onClick={() => downloadEvidenceOfOneCCF(ccfNameTitle)}>
                                <i className="ri-download-line text-dark me-2" style={{ fontSize: '16px' }}></i>
                                <p className='mb-0' style={{ textDecoration: 'underline', color: '#000', display: 'flex', alignItems: 'center', gap: '8px' }}>
                                    Download all evidence
                                </p>
                            </div>


                            {
                                role !== "AUDITOR" && (
                                    <>
                                        <Form >
                                            <Row>
                                                <Col lg={9}>
                                                    <Input
                                                        id="exampleFile"
                                                        name="file"
                                                        type="file"
                                                        onChange={handleFileChange}
                                                    />
                                                </Col>
                                                <Col lg={3}>

                                                    {
                                                        uploadFileLoading ? <Button color="secondary" className="me-2 py-1" type='button' disabled>
                                                            <Spinner size="sm"> Loading... </Spinner> </Button> : <Button color="secondary" className='py-1' type="submit" onClick={uploadEvidence}>
                                                            Upload
                                                        </Button>
                                                    }

                                                </Col>
                                            </Row>
                                        </Form>
                                        <p className="text-danger mb-0">File size must be less than 10 mb</p>
                                    </>
                                )
                            }


                            <ListGroup className='mt-4'>
                                {evidenceFile.map((file, index) => (
                                    <ListGroupItem className='mb-2 border d-flex justify-content-between py-0' key={index}>
                                        <div className="d-flex align-items-center">
                                            <i className="ri-bill-line align-middle text-muted fs-5 me-3"></i>
                                            <div>
                                                <p className="mb-0 fs-12 text-muted">{(file.file).split(/[/\\]/).pop()}</p>
                                                <h5 className='fs-11 mb-0'>{file.firstName}</h5>
                                            </div>
                                        </div>
                                        <div className="evd-action d-flex flex-column">
                                            {role === 'AUDITOR' ? (
                                                file.status === "unfreeze" || userID === file.status ? (
                                                    <Input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        id={`formCheck_${index}`}
                                                        checked={checkedStates[file.file] || false}
                                                        onChange={() => handleCheckboxClick(idForDeleteFile, pid, file.file)}
                                                    />
                                                ) : (
                                                    <i className="ri-git-repository-private-fill"></i>
                                                )
                                            ) : (role === 'CLIENT_USER' || role === 'CLIENT_ADMIN') ? (
                                                (file.ssoid === userID || projectManager === userID) && file.status === "unfreeze" ? (
                                                    <i
                                                        className="ri-delete-bin-line cursor-pointer"
                                                        onClick={() => deleteEvidence(idForDeleteFile, pid, file.file)}
                                                    ></i>
                                                ) : (
                                                    <i className="ri-git-repository-private-fill"></i>
                                                )
                                            ) : (
                                                <i className="ri-delete-bin-line cursor-pointer" onClick={() => deleteEvidence(idForDeleteFile, pid, file.file)}></i>
                                            )}
                                            <i className="ri-download-2-line cursor-pointer" onClick={() => downloadEvidence(idForDeleteFile, pid, file.file)}></i>
                                        </div>
                                    </ListGroupItem>
                                ))}

                            </ListGroup>

                        </TabPane>

                        <TabPane tabId="2" id="nav-border-justified-profile">

                            <Nav tabs className="nav-tabs mb-3">

                                {activityCommentsTab.map((tab, index) => (
                                    <NavItem key={index}>
                                        <NavLink
                                            style={{ cursor: "pointer", fontSize: '.7rem' }}
                                            className={classnames({ active: activeTab === (tab).toString() })}
                                            onClick={() => { toggle((tab).toString()); }}
                                        >
                                            {tab}
                                        </NavLink>
                                    </NavItem>
                                ))}

                            </Nav>

                            <TabContent activeTab={activeTab} className="text-muted">
                                {activityCommentsTab.map((tab, index) => (
                                    <TabPane tabId={(tab).toString()} key={index}>
                                        <div className="user-chat w-100 overflow-hidden minimal-border">
                                            <div className="chat-content d-lg-flex">
                                                <div className="w-100 overflow-hidden position-relative">
                                                    <div className="position-relative" id="users-chat">
                                                        <div className="chat-conversation p-3 p-lg-4" id="chat-conversation">
                                                            <PerfectScrollbar>
                                                                <ul className="list-unstyled chat-conversation-list" id="users-conversation">
                                                                    {messages[tab] && messages[tab].length > 0 ? (
                                                                        messages[tab].map((message, key) => {
                                                                            return (
                                                                                <li
                                                                                    className={message.ssoid === currentUser ? 'chat-list right' : 'chat-list left'}
                                                                                    key={key}
                                                                                >
                                                                                    <div className="conversation-list">
                                                                                        <div className="user-chat-content">
                                                                                            <div className="ctext-wrap">
                                                                                                <div className="ctext-wrap-content bg-success">
                                                                                                    <p className="mb-0 ctext-content text-white">
                                                                                                        {message.comment}
                                                                                                    </p>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="conversation-name">
                                                                                                <small className="text-muted fs-10 time">{message.date}</small>
                                                                                                <span className="text-success check-message-icon">
                                                                                                    <i className="ri-check-double-line align-bottom"></i>
                                                                                                </span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </li>
                                                                            );
                                                                        })
                                                                    ) : (
                                                                        <p>No messages available</p>
                                                                    )}
                                                                </ul>
                                                            </PerfectScrollbar>
                                                        </div>

                                                        <div className="chat-input-section pt-2">
                                                            <form id="chatinput-form" onSubmit={handleSendMessage}>
                                                                <Row className="g-0 align-items-center">
                                                                    <div className="col">
                                                                        <input
                                                                            type="text"
                                                                            className="form-control fs-11 chat-input bg-light border-light"
                                                                            placeholder="Type your message..."
                                                                            value={inputMessage}
                                                                            onChange={handleInputChange}
                                                                        />
                                                                    </div>
                                                                    <div className="col-auto">
                                                                        <div className="chat-input-links ms-2">
                                                                            <div className="links-list-item">
                                                                                <Button
                                                                                    type="submit"
                                                                                    color="success"
                                                                                    className="chat-send waves-effect waves-light"
                                                                                >
                                                                                    <i className="ri-send-plane-2-fill align-bottom"></i>
                                                                                </Button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Row>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </TabPane>
                                ))}
                            </TabContent>

                        </TabPane>

                        <TabPane tabId="3" id="nav-border-justified-messages">
                            <h6> References </h6>
                            <div className='mb-2'>
                                <Alert color="secondary" className="material-shadow py-1 mb-1 fs-12">
                                    Narrative
                                </Alert>
                                <ul className='pe-0 ps-3'>
                                    <li className='justify-text-w-sm-font' style={{ listStyle: 'none' }}>
                                        {referencesData.control_narrative}
                                    </li>
                                </ul>
                            </div>

                            <div className='mb-2'>
                                <Alert color="warning" className="material-shadow py-1 mb-1 fs-12">
                                    Artifacts
                                </Alert>
                                <ul className='pe-0 ps-3'>
                                    <li className='justify-text-w-sm-font' style={{ listStyle: 'none' }}>
                                        {referencesData.control_artifacts}
                                    </li>
                                </ul>
                            </div>

                        </TabPane>

                        <TabPane tabId="4" id="nav-border-justified-home">
                            <h6> Edit Mapped Compliance Status </h6>

                            {ccfStatusList.map((item, index) => {
                                const title = Object.keys(item)[0];
                                const currentStatus = status[title] || item[title].status;
                                return (
                                    <div className='mt-3' key={index}>
                                        <h5>{title}</h5>
                                        <UncontrolledDropdown>
                                            <DropdownToggle
                                                tag="button"
                                                className={`btn btn-sm ${status[title] === "COMPLETED" ? 'btn-success' :
                                                    status[title] === "REJECTED" ? 'btn-danger' :
                                                        status[title] === "PENDING" ? 'btn-warning' :
                                                            status[title] === "PROGRESSING" ? 'btn-info' : 'btn-secondary'}`}
                                                id="dropdownMenuButton"
                                            >
                                                Status: {currentStatus} <i className="mdi mdi-chevron-down"></i>
                                            </DropdownToggle>
                                            <DropdownMenu>
                                                {statusOptions.map((statusOption, idx) => (
                                                    <DropdownItem key={idx} onClick={() => handleStatusChange(title, statusOption)}>
                                                        {statusOption}
                                                    </DropdownItem>
                                                ))}
                                            </DropdownMenu>
                                        </UncontrolledDropdown>
                                    </div>
                                );
                            })}

                        </TabPane>

                    </TabContent>

                </OffcanvasBody>
            </Offcanvas>


            <Modal isOpen={downloadEvidenceModal} toggle={downloadEvidenceModalPopup} backdrop="static" centered>
                <ModalBody>
                    <div className="text-center">
                        <h4>Your file is downloading</h4>
                        <p className="text-muted mx-4 mb-2">
                            File downloading in progreess please wait
                        </p>
                        <p className="text-danger mb-4">Don't reload or refresh the window</p>
                        <Spinner>
                            Loading...
                        </Spinner>
                    </div>
                </ModalBody>
            </Modal>

        </>
    )
}