import CountUp from "react-countup";
import { Card, CardBody, Col } from 'reactstrap';

const WidgetCard = ({ title, count, iconClass, iconBgClass, textColor, colSize }) => (
    <Col lg={colSize} className='pe-1 ps-1'>
        <Card className="card-animate">
            <CardBody className='p-2'>
                <div className="d-flex justify-content-between">
                    <div>
                        <p className="text-uppercase fw-medium text-muted text-truncate mb-0">{title}</p>
                        <h4 className="fw-semibold ff-secondary mb-0">
                            <span className="counter-value fs-13">
                                {count !== undefined ? (
                                    <CountUp start={0} end={count} duration={2} />
                                ) : (
                                    'Loading...'
                                )}
                            </span>
                        </h4>
                    </div>
                    <div className="d-flex align-items-center">
                        <div className="avatar-xxs flex-shrink-0">
                            <span className={`avatar-title rounded fs-14 ${iconBgClass}`}>
                                <i className={`${textColor} ${iconClass}`}></i>
                            </span>
                        </div>
                    </div>
                </div>
            </CardBody>
        </Card>
    </Col>
);

const Widgets = ({ usersCount, policiesCount, assetsCount, auditorsCount, colSize, role }) => {

    return (
        <>
            <WidgetCard
                title="Users"
                count={usersCount}
                iconClass="bx bxs-user-circle"
                iconBgClass="bg-success-subtle"
                textColor="text-success"
                colSize={colSize}
            />
            <WidgetCard
                title="Policies"
                count={policiesCount}
                iconClass="bx bxs-dock-right"
                iconBgClass="bg-warning-subtle"
                textColor="text-warning"
                colSize={colSize}
            />
            <WidgetCard
                title="Total Assets"
                count={assetsCount}
                iconClass="bx bxs-credit-card"
                iconBgClass="bg-primary-subtle"
                textColor="text-primary"
                colSize={colSize}
            />

            {role !== 'AUDITOR' && (
                <WidgetCard
                    title="Auditors"
                    count={auditorsCount}
                    iconClass="bx bxs-user-account"
                    iconBgClass="bg-info-subtle"
                    textColor="text-info"
                    colSize={colSize}
                />
            )}
        </>
    );
};

export default Widgets;