import React from 'react';
import { Card, CardBody, CardHeader, Col } from 'reactstrap';
import userImg from "../../assets/images/users/user-dummy-img.jpg";

const ActiveCompliance = ({ title, activeComplianceData }) => {

    const IMAGE_URL = process.env.REACT_APP_API_IMAGE_URL;

    return (
        <>
            <Col xl={7} lg={7} className='pe-1 ps-1'>
                <Card className='dashboardTableTwo'>
                    <CardHeader className="align-items-center d-flex py-2">
                        <h4 className="card-title mb-0 flex-grow-1">{title}</h4>
                    </CardHeader>

                    <CardBody>
                        <div className="table-responsive table-card">
                            <div className='scrollTable'>
                                {activeComplianceData.length === 0 ? (
                                    <div className="text-center my-3">
                                        <p>No data available</p>
                                    </div>
                                ) : (
                                    <table className="table table-borderless table-centered align-middle table-nowrap mb-0">
                                        <thead className="text-muted table-light" style={{ position: 'sticky', top: 0, backgroundColor: 'white', zIndex: 1 }}>
                                            <tr>
                                                <th scope="col" className='py-1 fs-12'>Compliance</th>
                                                <th scope="col" className='py-1 fs-12'>Auditor Name</th>
                                                <th scope="col" className='py-1 fs-12'>Date</th>
                                                <th scope="col" className='py-1 fs-12'>Pending Items</th>
                                                <th scope="col" className='py-1 fs-12'>Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {(activeComplianceData || []).map((item, key) => (<tr key={key}>
                                                <td>
                                                    {item.name}
                                                </td>
                                                <td className="d-flex">
                                                    {
                                                        !item.auditorProfileImage ?
                                                            <img
                                                                src={userImg}
                                                                className="avatar-xxs rounded-circle me-1 material-shadow"
                                                                alt="..."
                                                            /> :
                                                            <img
                                                                src={IMAGE_URL + item.auditorProfileImage}
                                                                className="avatar-xxs rounded-circle me-1 material-shadow"
                                                                alt="..."
                                                                onError={(e) => { e.target.src = userImg }}
                                                            />
                                                    }
                                                    <div>
                                                        <h5 className="fs-13 mb-0">{item.auditorName}</h5>
                                                    </div>
                                                </td>
                                                <td>
                                                    <h6 className="mb-0">{item.other}</h6>
                                                </td>
                                                <td className='d-flex'>
                                                    <div style={{ width: '40%' }}>{item.completed} / {item.total}</div>
                                                    <div
                                                        style={{
                                                            position: "relative",
                                                            height: "1.5rem",
                                                            width: "1.5rem",
                                                            borderRadius: "50%",
                                                            background: `conic-gradient(#012543 ${item.percent * 36}deg, #e0e0e0 0deg)`,
                                                            display: "flex",
                                                            alignItems: "center",
                                                            justifyContent: "center",
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                height: "1rem",
                                                                width: "1rem",
                                                                borderRadius: "50%",
                                                                background: "#fff",
                                                            }}
                                                        ></div>
                                                    </div>
                                                </td>
                                                <td className='py-0 fs-12' style={{ width: '20%' }}>
                                                    <span
                                                        className={`badge  
                                                                    ${item.status === 'ACTIVE' ? 'bg-success-subtle text-success' : ''} 
                                                                    ${item.status === 'EXPIRED' ? 'bg-danger-subtle text-danger' : ''} 
                                                                    ${item.status === 'EXPIRING' ? 'bg-warning-subtle text-warning' : ''} 
                                                                    ${item.status === 'INACTIVE' ? 'bg-dark-subtle text-dark' : ''} 
                                                                    `}>
                                                        {item.status}
                                                    </span>
                                                </td>
                                            </tr>))}
                                        </tbody>
                                    </table>
                                )}
                            </div>
                        </div>
                    </CardBody>
                </Card>
            </Col>
        </>
    );
};

export default ActiveCompliance;
