import { Card, CardBody, CardHeader } from 'reactstrap';

const DepartmentCompliance = ({ title, departmentComplianceData, heightMaxer }) => {


    return (
        <>
            <Card className='dashboardTableOne'>
                <CardHeader className="align-items-center d-flex py-2">
                    <h4 className="card-title mb-0 flex-grow-1">{title}</h4>
                </CardHeader>

                <CardBody>
                    <div className="table-responsive table-card">
                        <div className='scrollTable' style={{ maxHeight: heightMaxer.length === 0 ? '16.5rem' : '30rem' }}>
                            {departmentComplianceData.length === 0 ? (
                                <div className="text-center my-3">
                                    <p>No data available</p>
                                </div>
                            ) : (
                                <table className="table table-borderless table-centered align-middle table-nowrap mb-0">
                                    <thead className="text-muted table-light" style={{ position: 'sticky', top: 0, backgroundColor: 'white', zIndex: 1 }}>
                                        <tr>
                                            <th scope="col" className='py-1 fs-12'>Department</th>
                                            <th scope="col" className='py-1 fs-12'>Control Applicable</th>
                                            <th scope="col" className='py-1 fs-12'>Compliance Percentage</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {(departmentComplianceData || []).map((item, key) => (
                                            <tr key={key} className='border-bottom border-bottom-dashed'>
                                                <td className='py-2 fs-12'>{item.name}</td>
                                                <td className='py-2 fs-12'>{item.completed}/{item.total}</td>
                                                <td className='py-2 fs-12'>
                                                    <div className="d-flex align-items-center">
                                                        <div className='me-2'>{item.percent}%</div>
                                                        <div className="progress progress-sm  flex-grow-1" style={{ width: "68%" }}>
                                                            <div className="progress-bar bg-primary rounded" role="progressbar" style={{ width: item.percent }} aria-valuenow="53" aria-valuemin="0" aria-valuemax="100"></div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            )}
                        </div>
                    </div>
                </CardBody>
            </Card>
        </>
    );
};

export default DepartmentCompliance;