import React from 'react';
import { Card, CardBody, CardHeader, Col } from 'reactstrap';
import { CertificationComplianceChart, EvidenceStatusChart, OverallComplianceChart } from './DashboardCharts';

export const OverallComplianceChartCard = ({ title, overallComplianceData }) => {

    return (
        <>
            <Col xl={6} lg={6} className='pe-1 ps-1'>
                <Card className="chart-card">
                    <CardHeader className="align-items-center d-flex py-2">
                        <h4 className="card-title mb-0 flex-grow-1">{title}</h4>
                    </CardHeader>

                    <div className="card-body">
                        <OverallComplianceChart data={overallComplianceData} />
                        <div className="mt-1 px-2">
                            <div className="d-flex justify-content-center align-items-center mb-2">
                                <h2 className="me-3 ff-secondary fs-5 mb-0">{overallComplianceData.totalCCF}</h2>
                                <div>
                                    <p className="text-muted mb-0 fs-12">Total Controls</p>
                                </div>
                            </div>

                            <div className="d-flex justify-content-between border-bottom border-bottom-dashed py-1">
                                <p className="fw-medium mb-0 fs-12"><i className="ri-checkbox-blank-circle-fill align-middle me-2" style={{ color: "rgb(0, 143, 251)" }}></i> Total</p>
                                <div>
                                    <span className="text-success fw-medium fs-11">{overallComplianceData.totalCCF} Controls</span>
                                </div>
                            </div>
                            <div className="d-flex justify-content-between border-bottom border-bottom-dashed py-1">
                                <p className="fw-medium mb-0 fs-12"><i className="ri-checkbox-blank-circle-fill align-middle me-2" style={{ color: "rgb(0, 227, 150)" }}></i> Completed</p>
                                <div>
                                    <span className="text-success fw-medium fs-11">{overallComplianceData.completedCCF} Controls</span>
                                </div>
                            </div>
                            <div className="d-flex justify-content-between border-bottom border-bottom-dashed py-1">
                                <p className="fw-medium mb-0 fs-12"><i className="ri-checkbox-blank-circle-fill align-middle me-2" style={{ color: "rgb(254, 176, 25)" }}></i> Pending</p>
                                <div>
                                    <span className="text-success fw-medium fs-11">{overallComplianceData.pendingCCF} Controls</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </Card>
            </Col>
        </>
    );
};

export const EvidenceStatusChartCard = ({ title, evidenceStatusData }) => {

    return (
        <>
            <Col xl={6} lg={6} className='pe-1 ps-1'>
                <Card className='chart-card'>
                    <CardHeader className='py-2'>
                        <h4 className="card-title mb-0">{title}</h4>
                    </CardHeader>

                    <CardBody >
                        <EvidenceStatusChart data={evidenceStatusData} />
                    </CardBody>
                </Card>
            </Col>
        </>
    )
}

export const CertificationComplianceChartCard = ({ title, certificationComplianceData }) => {

    return (
        <>
        {
            certificationComplianceData.length === 0 ? null : (
                <Col xl={12} lg={12} className='pe-1 ps-1'>
                    <Card className='chart-card'>
                        <CardHeader className='py-2'>
                            <h4 className="card-title mb-0">{title}</h4>
                        </CardHeader>
                        <CardBody>
                            <CertificationComplianceChart data={certificationComplianceData} />
                        </CardBody>
                    </Card>
                </Col>
            )
        }
        </>
    );
};