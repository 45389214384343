import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'reactstrap'
import BreadCrumb from '../../Components/Common/BreadCrumb'
import UsersTable from './UsersTable';
import './index.css'
import { getAllUser } from '../../apiConfig/apiCall';
import Cookies from 'js-cookie';
import DataLoading from '../../Components/Common/DataLoading';

const UsersList = () => {

    const [loading, setLoading] = useState(true);

    // Get All Users List Logic Start
    const [userRole, setUserRole] = useState("CLIENT_USER");
    const [users, setUsers] = useState([]);

    const CLIENT_ID = Cookies.get('CLIENT_ID');

    const getUsers = async (userRole, CLIENT_ID) => {
        try {
            const apiresponse = await getAllUser(userRole, CLIENT_ID);
            if (apiresponse.status === 200) {
                setUsers(apiresponse.data);
            }
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            await getUsers(userRole, CLIENT_ID);
            setLoading(false);
        };
        fetchData();
    }, []);

    return (
        <>
            {loading ? (
                <DataLoading />
            ) : (
                <div className="page-content users-page">
                    <Container fluid>
                        <BreadCrumb title={'Users List'} pageTitle={'Dashboard'} />

                        <Row>
                            <Col lg={12}>
                                <UsersTable users={users} userRole={userRole} getUsers={getUsers} CLIENT_ID={CLIENT_ID} />
                            </Col>
                        </Row>

                    </Container>
                </div>
            )}
        </>
    )
}

export default UsersList