import React, { useState, useMemo, useEffect } from 'react';
import TableContainer from "../../Components/Common/TableContainerReactTable";
import { Collapse, Card, CardBody, Button, Modal, ModalHeader, ModalBody, Form, Row, Col, Label, Input } from 'reactstrap';
import useReactSelectCustomStyle from '../../hooks/useReactSelectCustomStyle';
import Select from 'react-select';
import { addAssetApi, deleteAssetApi, editAssetApi, getAssetsApi, getSingleAssetApi } from '../../apiConfig/apiCall';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import Cookies from 'js-cookie';

const ReactTable = ({ assetListData, gteAssets }) => {

  const role = Cookies.get('role');

  const [selectedRows, setSelectedRows] = useState([]);
  const [expandedRowId, setExpandedRowId] = useState(null);

  // Add Form Feild Logic
  const [formData, setFormData] = useState({
    assetName: '',
    ipAddress: '',
    location: '',
    assetType: '',
    purposeOrFunctionOfDevice: '',
    ownerOfAsset: '',
    status: '',
    entryDate: "2024-09-30"
  });

  const handleHeaderCheckboxChange = () => {
    if (selectedRows.length === assetListData.length) {
      setSelectedRows([]);
    } else {
      setSelectedRows(assetListData.map(row => row.id));
    }
  };

  const handleRowCheckboxChange = (id) => {
    setSelectedRows((prevSelectedRows) => {
      if (prevSelectedRows.includes(id)) {
        return prevSelectedRows.filter(rowId => rowId !== id);
      } else {
        return [...prevSelectedRows, id];
      }
    });
  };

  const handleView = (id) => {
    setExpandedRowId(prevId => (prevId === id ? null : id));
  };

  const columns = useMemo(() => [
    {
      header: <input
        type="checkbox"
        checked={selectedRows.length === assetListData.length}
        onChange={handleHeaderCheckboxChange}
      />,
      accessorKey: "checkbox",
      cell: ({ row }) => (
        <input
          type="checkbox"
          checked={selectedRows.includes(row.original.id)}
          onChange={() => handleRowCheckboxChange(row.original.id)}
        />
      ),
      enableSorting: false,
      enableColumnFilter: false,
    },
    {
      header: "ID",
      cell: (cell) => <span className="fw-semibold">{cell.getValue()}</span>,
      accessorKey: "id",
      enableColumnFilter: false,
    },
    {
      header: "Asset Name",
      accessorKey: "assetName",
      enableColumnFilter: false,
    },
    {
      header: "IP Address",
      accessorKey: "ipAddress",
      enableColumnFilter: false,
    },
    {
      header: "Location",
      accessorKey: "location",
      enableColumnFilter: false,
    },
    {
      header: "Asset Type",
      accessorKey: "assetType",
      enableColumnFilter: false,
    },
    {
      header: "Compliance Status",
      accessorKey: "status",
      cell: (cell) => {
        switch (cell.getValue()) {
          case "ACTIVE":
            return <span className="badge bg-success-subtle text-success text-uppercase">{cell.getValue()}</span>;
          case "INACTIVE":
            return <span className="badge bg-warning-subtle text-danger text-uppercase">{cell.getValue()}</span>;
          default:
            return <span className="badge bg-danger-subtle text-warning text-uppercase">{cell.getValue()}</span>;
        }
      },
      enableColumnFilter: false,
    },
    {
      header: "Entry Date",
      accessorKey: "entryDate",
      enableColumnFilter: false,
    },
    {
      header: "Actions",
      accessorKey: "actions",
      cell: ({ row }) => (
        <div className='actions d-flex'>
          <Button className='me-2' color="primary" size="sm" onClick={() => handleView(row.original.id)}>
            <i className="ri-eye-fill"></i>
          </Button>

          <Button className='me-2' color="success" size="sm" onClick={() => handleAssetEditModal(row.original.id)}>
            <i className='bx bxs-edit'></i>
          </Button>

          {
            role !== 'AUDITOR' && (
              <Button className='me-2' color="danger" size="sm" onClick={() => handleDelete(row.original.id)}>
                <i className='bx bxs-trash-alt'></i>
              </Button>
            )
          }

          <Button color="secondary" size="sm"> Run </Button>
        </div>
      ),
      enableSorting: false,
      enableColumnFilter: false,
    }
  ], [selectedRows]);

  // Prepare table data including expanded content rows
  const tableData = assetListData.map(row => ({
    ...row,
    expandedContent: expandedRowId === row.id ? (
      <Collapse className='tableDownAccordion mt-0' isOpen={expandedRowId === row.id}>
        <Card className='mb-0'>
          <CardBody>
            <Row>
              <Col lg={4}>
                <p><strong>ID:</strong> {row.id}</p>
                <p><strong>Asset Name:</strong> {row.assetName}</p>
                <p><strong>Asset Type:</strong> {row.assetType}</p>
                <p><strong>Entry By:</strong> {row.entryBy}</p>
                <p className='mb-0'><strong>Entry Date:</strong> {row.entryDate}</p>
              </Col>

              <Col lg={6}>
                <p><strong>IP Address:</strong> {row.ipAddress}</p>
                <p><strong>Location:</strong> {row.location}</p>
                <p><strong>Owner Of Asset:</strong> {row.ownerOfAsset}</p>
                <p><strong>Purpose Or Function Of Device:</strong> {row.purposeOrFunctionOfDevice}</p>
                <p className='mb-0'><strong>status:</strong> {row.status}</p>
              </Col>
            </Row>

          </CardBody>
        </Card>
      </Collapse>
    ) : null,
  }));

  // Add Asset Modal
  const [assetAddModalOpen, setAssetAddModalOpen] = useState(false);
  const toggleAssetAddModal = () => setAssetAddModalOpen(!assetAddModalOpen);

  // Edit Asset Modal
  const [assetEditModalOpen, setAssetEditModalOpen] = useState(false);
  const toggleAssetEditModal = () => setAssetEditModalOpen(!assetEditModalOpen);

  const [inputValues, setInputValues] = useState({
    assetName: '',
    ipAddress: '',
    location: '',
    assetType: '',
    purposeOrFunctionOfDevice: '',
    ownerOfAsset: '',
    status: '',
    entryDate: "2024-09-30"
  });


  const selectBoxCustomStyles = useReactSelectCustomStyle()

  const StatusOptions = [
    { value: 'ACTIVE', label: 'ACTIVE' },
    { value: 'INACTIVE', label: 'INACTIVE' },
  ];

  const AssetTypeOptions = [
    { value: 'Amazon Web Services', label: 'Amazon Web Services' },
    { value: 'Microsoft Azure', label: 'Microsoft Azure' },
    { value: 'Google Cloud Platform', label: 'Google Cloud Platform' },
    { value: 'Laptop', label: 'Laptop' },
    { value: 'Desktop', label: 'Desktop' },
  ];


  const handleDelete = async (ASSET_ID) => {
    const result = await Swal.fire({
      width: '20rem',
      padding: '0 0 1rem 0',
      title: "Are you sure?",
      text: `You won't be able to revert this!`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!"
    });

    if (result.isConfirmed) {
      const apiResponse = await deleteAssetApi(ASSET_ID);
      if (apiResponse.status === 200) {
        toast.success(apiResponse.data);
        gteAssets();
      }
      Swal.fire({
        width: '20rem',
        padding: '0 0 1rem 0',
        title: "Deleted!",
        text: `${ASSET_ID} has been deleted.`,
        icon: "success",
      });
    }
  }



  // Handle Logic Of Add Asset
  // Handle input changes
  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // Handle Select component changes
  const handleSelectChange = (selectedOption, { name }) => {
    setFormData({ ...formData, [name]: selectedOption.value });
  };

  const handleAddAssetSubmit = async (e) => {
    e.preventDefault();
    try {
      const apiResponse = await addAssetApi(formData);
      if (apiResponse.status === 201) {
        toast.success(apiResponse.data);
        setAssetAddModalOpen(false)
        gteAssets();
      }
    } catch (error) {
      console.error('Error adding asset:', error);
    }
  };


  // Handle Edit Asset Modal
  const [assetId, setAssetId] = useState();
  const handleEditSelectChange = (selectedOption, { name }) => {
    setInputValues({ ...inputValues, [name]: selectedOption.value });
  };

  const handleEditInputChange = (e) => {
    setInputValues({
      ...inputValues,
      [e.target.name]: e.target.value
    });
  };

  const handleAssetEditModal = async (ASSET_ID) => {
    try {
      setAssetEditModalOpen(true);
      setAssetId(ASSET_ID);
      const apiResponse = await getSingleAssetApi(ASSET_ID);
      if (apiResponse.status === 200) {
        const singleAssetData = apiResponse.data;

        setInputValues({
          assetName: singleAssetData.assetName || '',
          ipAddress: singleAssetData.ipAddress || '',
          location: singleAssetData.location || '',
          assetType: singleAssetData.assetType || '',
          purposeOrFunctionOfDevice: singleAssetData.purposeOrFunctionOfDevice || '',
          ownerOfAsset: singleAssetData.ownerOfAsset || '',
          status: singleAssetData.status || '',
          entryDate: "2024-09-30" || '',
          clientCompanyId: singleAssetData.clientCompanyId || '',
          id: singleAssetData.id || '',
        });
      }
    } catch (error) {
      console.log(error);
    }
  }

  const handleEditAssetSubmit = async (e) => {
    e.preventDefault();
    try {
      const apiResponse = await editAssetApi(assetId, inputValues);
      if (apiResponse.status === 200) {
        toast.success(apiResponse.data);
        setAssetEditModalOpen(false)
        gteAssets();
      }
    } catch (error) {
      console.error('Error adding asset:', error);
    }
  }



  return (
    <>

      <TableContainer
        columns={columns}
        data={tableData}
        isGlobalFilter={true}
        customPageSize={5}
        theadClass="text-muted table-light"
        SearchPlaceholder="Search..."
        customAddButton={role === 'AUDITOR' ? null : <Button color='primary' className='btn-sm' onClick={toggleAssetAddModal}>Add Asset</Button>}
      />

      {/* Add Asset Modal */}
      <Modal size='lg' isOpen={assetAddModalOpen} toggle={toggleAssetAddModal}>
        <ModalHeader toggle={toggleAssetAddModal}>Add Asset</ModalHeader>
        <ModalBody>
          <Form onSubmit={handleAddAssetSubmit}>
            <Row className="gy-4">
              <Col lg={6}>
                <div>
                  <Label htmlFor="assetName" className="form-label">Asset Name</Label>
                  <Input
                    type="text"
                    className="form-control"
                    id="assetName"
                    name="assetName"
                    placeholder="Enter asset name"
                    value={formData.assetName}
                    onChange={handleInputChange}
                  />
                </div>
              </Col>

              <Col lg={6}>
                <div>
                  <Label htmlFor="ipAddress" className="form-label">IP Address</Label>
                  <Input
                    type="text"
                    className="form-control"
                    id="ipAddress"
                    name="ipAddress"
                    placeholder="Enter IP address"
                    value={formData.ipAddress}
                    onChange={handleInputChange}
                  />
                </div>
              </Col>

              <Col lg={6}>
                <div>
                  <Label htmlFor="location" className="form-label">Location</Label>
                  <Input
                    type="text"
                    className="form-control"
                    id="location"
                    name="location"
                    placeholder="Enter location"
                    value={formData.location}
                    onChange={handleInputChange}
                  />
                </div>
              </Col>

              <Col lg={6}>
                <div>
                  <Label htmlFor="assetType" className="form-label">Asset Type</Label>
                  <Select
                    name="assetType"
                    value={AssetTypeOptions.find(option => option.value === formData.assetType)}
                    onChange={handleSelectChange}
                    options={AssetTypeOptions}
                    styles={selectBoxCustomStyles}
                    placeholder="Select asset type"
                  />
                </div>
              </Col>

              <Col lg={6}>
                <div>
                  <Label htmlFor="purposeOrFunctionOfDevice" className="form-label">Purpose or Function of Device</Label>
                  <Input
                    type="text"
                    className="form-control"
                    id="purposeOrFunctionOfDevice"
                    name="purposeOrFunctionOfDevice"
                    placeholder="Enter purpose or function of device"
                    value={formData.purposeOrFunctionOfDevice}
                    onChange={handleInputChange}
                  />
                </div>
              </Col>

              <Col lg={6}>
                <div>
                  <Label htmlFor="ownerOfAsset" className="form-label">Owner of Asset</Label>
                  <Input
                    type="text"
                    className="form-control"
                    id="ownerOfAsset"
                    name="ownerOfAsset"
                    placeholder="Enter owner of asset"
                    value={formData.ownerOfAsset}
                    onChange={handleInputChange}
                  />
                </div>
              </Col>

              <Col lg={6}>
                <div>
                  <Label htmlFor="status" className="form-label">Status</Label>
                  <Select
                    name="status"
                    value={StatusOptions.find(option => option.value === formData.status)}
                    onChange={handleSelectChange}
                    options={StatusOptions}
                    styles={selectBoxCustomStyles}
                    placeholder="Select status"
                  />
                </div>
              </Col>

              <Col lg={6}>
                <Button color="primary" className="btn-sm mt-4" type="submit">Add Asset</Button>
              </Col>
            </Row>
          </Form>
        </ModalBody>
      </Modal>

      {/* Edit Asset Modal */}
      <Modal size='lg' isOpen={assetEditModalOpen} toggle={toggleAssetEditModal}>
        <ModalHeader toggle={toggleAssetEditModal}>Edit Asset</ModalHeader>
        <ModalBody>
          <Form onSubmit={handleEditAssetSubmit}>
            <Row className="gy-4">
              <Col lg={6}>
                <div>
                  <Label htmlFor="assetName" className="form-label">Asset Name</Label>
                  <Input
                    type="text"
                    className="form-control"
                    id="assetName"
                    name="assetName"
                    placeholder="Enter asset name"
                    value={inputValues.assetName}
                    onChange={handleEditInputChange}
                  />
                </div>
              </Col>

              <Col lg={6}>
                <div>
                  <Label htmlFor="ipAddress" className="form-label">IP Address</Label>
                  <Input
                    type="text"
                    className="form-control"
                    id="ipAddress"
                    name="ipAddress"
                    placeholder="Enter IP address"
                    value={inputValues.ipAddress}
                    onChange={handleEditInputChange}
                  />
                </div>
              </Col>

              <Col lg={6}>
                <div>
                  <Label htmlFor="location" className="form-label">Location</Label>
                  <Input
                    type="text"
                    className="form-control"
                    id="location"
                    name="location"
                    placeholder="Enter location"
                    value={inputValues.location}
                    onChange={handleEditInputChange}
                  />
                </div>
              </Col>

              <Col lg={6}>
                <div>
                  <Label htmlFor="assetType" className="form-label">Asset Type</Label>
                  <Select
                    name="assetType"
                    value={AssetTypeOptions.find(option => option.value === inputValues.assetType)}
                    onChange={handleEditSelectChange}
                    options={AssetTypeOptions}
                    styles={selectBoxCustomStyles}
                    placeholder="Select asset type"
                  />
                </div>
              </Col>

              <Col lg={6}>
                <div>
                  <Label htmlFor="purposeOrFunctionOfDevice" className="form-label">Purpose or Function of Device</Label>
                  <Input
                    type="text"
                    className="form-control"
                    id="purposeOrFunctionOfDevice"
                    name="purposeOrFunctionOfDevice"
                    placeholder="Enter purpose or function of device"
                    value={inputValues.purposeOrFunctionOfDevice}
                    onChange={handleEditInputChange}
                  />
                </div>
              </Col>

              <Col lg={6}>
                <div>
                  <Label htmlFor="ownerOfAsset" className="form-label">Owner of Asset</Label>
                  <Input
                    type="text"
                    className="form-control"
                    id="ownerOfAsset"
                    name="ownerOfAsset"
                    placeholder="Enter owner of asset"
                    value={inputValues.ownerOfAsset}
                    onChange={handleEditInputChange}
                  />
                </div>
              </Col>

              <Col lg={6}>
                <div>
                  <Label htmlFor="status" className="form-label">Status</Label>
                  <Select
                    name="status"
                    value={StatusOptions.find(option => option.value === inputValues.status)}
                    onChange={handleEditSelectChange}
                    options={StatusOptions}
                    styles={selectBoxCustomStyles}
                    placeholder="Select status"
                  />
                </div>
              </Col>

              <Col lg={6}>
                <Button color="primary" className="btn-sm mt-4" type="submit">Update Asset</Button>
              </Col>
            </Row>
          </Form>
        </ModalBody>
      </Modal>
    </>
  );
};

export { ReactTable };