import Cookies from 'js-cookie';

export const saveQueryParamsToCookies = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const token = queryParams.get('token');
  const username = queryParams.get('username');

  if (token) {
    Cookies.set('x-http-core', token, { path: '/' }); 
  }
  if (username) {
    Cookies.set('user', username, { path: '/' }); 
  }
};
