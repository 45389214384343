import { Col } from "reactstrap";
import ReactApexChart from "react-apexcharts";

export const ComplianceChartCard = ({ name, total, pending, completed }) => {
    const series = [
        {
            name: "Total",
            data: [total],
        },
        {
            name: "Pending",
            data: [pending],
        },
        {
            name: "Completed",
            data: [completed],
        },
    ];

    const options = {
        chart: {
            type: 'bar',
            toolbar: {
                show: false,
            }
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '45%',
                endingShape: 'rounded'
            },
        },
        dataLabels: {
            enabled: false,
        },
        stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
        },
        colors: ['#405189', '#FFA500', '#0ab39c'],
        xaxis: {
            categories: [name],
        },
        grid: {
            borderColor: '#f1f1f1',
        },
        fill: {
            opacity: 1
        },
        tooltip: {
            y: {
                formatter: function (val) {
                    return val;
                }
            }
        },
        legend: {
            show: false
        }
    };

    return (
        <Col lg={12} className='pe-1 ps-1'>
            <ReactApexChart
                dir="ltr"
                className="apex-charts"
                series={series}
                options={options}
                type="bar"
                height={140}
            />
        </Col>
    );
};
