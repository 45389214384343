import React, { useEffect, useState } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import Cookies from 'js-cookie';
import { getLoggedInUserAPI } from "../apiConfig/apiCall";
import { toast } from "react-toastify";
import Loader from '../Components/Common/Loader'

export const AuthProtected = ({ children }) => {
  const token = Cookies.get('x-http-core');
  const username = Cookies.get('user');

  const location = useLocation();
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();
  const getLoggedInUserDetails = async (username) => {
    try {
      const apiResponse = await getLoggedInUserAPI(username); 
      if (apiResponse === undefined) {
        setIsAuthenticated(false);
        toast.error("Check your api or network");
      }
      else if (apiResponse.status === 200) {
        apiResponse.data.userProfiles.map((userProfile)=>{
          Cookies.set('role', userProfile.type);
        })
        Cookies.set('CLIENT_ID', apiResponse.data.clientCompanyId);
        Cookies.set('Firstname', apiResponse.data.firstName);
        Cookies.set('Designation', apiResponse.data.designation);
        Cookies.set('Company', apiResponse.data.clientCompany);
        setIsAuthenticated(true);
      } else if (apiResponse.status === 401) {
        setIsAuthenticated(false);        
      }
    } catch (error) {
      toast.error("Error fetching user details");
      setIsAuthenticated(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (token) {
      getLoggedInUserDetails(username);
    } else {
      setIsAuthenticated(false);
      setLoading(false);
    }
  }, [token]);

  if (loading) {
    return (
      <>
        <Loader />
      </>
    )
  }

  if (isAuthenticated === false) {
    return <Navigate to="/login" state={{ from: location }} />;
  }

  return <>{children}</>;
};