import { useState } from 'react';

export default function useReactSelectCustomStyle () {
  const [selectBoxCustomStyles] = useState({
    control: (provided, state) => ({
      ...provided,
      border: state.isFocused || state.isActive ? '1px solid #012543' : provided.border,
      borderRadius: '1px',
      minHeight: '30px',
      height: '30px',
      boxShadow: state.isFocused || state.isActive ? '1px solid #012543' : provided.boxShadow,
      '&:hover': {
        border: '1px solid hsl(0, 0%, 80%)',
      },
    }),
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? '#000' : '#000',
      backgroundColor: state.isFocused ? '#adb5bd' : '#fff',
      '&:hover': {
        backgroundColor: '#012543',
        color: 'white',
      },
      '&:active': {
        backgroundColor: '#012543',
        color: 'white',
      },
    }),
  });

  return selectBoxCustomStyles;
};