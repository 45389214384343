import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Card, CardBody, CardHeader, Col, Input, Row, Spinner, TabPane, UncontrolledCollapse } from 'reactstrap';
import SimpleBar from 'simplebar-react';
import { getCCFControlsApi, updateApplicableChangeAPI } from '../../../apiConfig/apiCall';

export default function OtherComplianceData({ otherComplianceData, setOtherComplianceData, otherComplianceTabName, toggleEvidence, pid, activeFilter, setActiveFilter, originalOtherComplianceData, currentOtherComplianceNameShow, pcid }) {

    const role = Cookies.get('role');

    // All States
    const [loading, setLoading] = useState(false);
    const [filteredData, setFilteredData] = useState(otherComplianceData);
    const [openDropdownForOtherControls, setOpenDropdownForOtherControls] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');

    // Handle Pagination Logic For CCF Start
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 5;
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;

    const filteredCCFData = filteredData && filteredData.filter(data => {
        const searchQueryLower = searchQuery.toLowerCase();

        if (data.cvp) {
            if (data.cvp.certificationIndex && data.cvp.certificationIndex.toLowerCase().includes(searchQueryLower)) {
                return true;
            }
            if (data.cvp.controlWording && data.cvp.controlWording.toLowerCase().includes(searchQueryLower)) {
                return true;
            }
        }   

        return false;
    });

    const paginateCCFData = filteredCCFData.slice(indexOfFirstItem, indexOfLastItem);

    const handleNextPage = () => {
        if (currentPage < Math.ceil(otherComplianceData.length / itemsPerPage)) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };
    // Handle Pagination Logic For CCF End

    // Toggle Other Complaince Controls Start
    const toggleDropdownForOtherControls = (id) => {
        setOpenDropdownForOtherControls(openDropdownForOtherControls === id ? null : id);
    };
    // Toggle Other Complaince Controls End

    // Handle Filter CCF From Controls Start
    const [currentCCFFilterControlApply, setCurrentCCFFilterControlApply] = useState('');
    const handleFilterCCFFromControls = async (controlValue) => {
        console.log(controlValue);
        try {
            setLoading(true); 
            setCurrentPage(1); 
            setCurrentCCFFilterControlApply(controlValue.version);
    
            setTimeout(() => {
                if (controlValue === "PCIDSS40") {
                    setOtherComplianceData(originalOtherComplianceData); 
                    setCurrentCCFFilterControlApply('All PCIDSS40');
                } else if (controlValue === "ISO270012022") {
                    setOtherComplianceData(originalOtherComplianceData);
                    setCurrentCCFFilterControlApply('All ISO270012022');
                } else if (controlValue === "SOC2") {
                    setOtherComplianceData(originalOtherComplianceData);
                    setCurrentCCFFilterControlApply('All SOC2');
                } else {
                    const filteredCCF = originalOtherComplianceData.filter(data =>
                        Array.isArray(data.cvp?.ccfNameList) &&
                        data.cvp.ccfNameList.some(ccfItem =>
                            ccfItem.Department === controlValue.version ||
                            ccfItem.DomainTitle === controlValue.version ||
                            ccfItem.ControlType === controlValue.version
                        )
                    );
                    setOtherComplianceData(filteredCCF); 
                    setCurrentPage(1);
                }
                setLoading(false); 
            }, 500); 
        } catch (error) {
            console.log(error);
            setLoading(false); 
        }
    };    
    // Handle Filter CCF From Controls End


    // Search CCF Logic Start
    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
        setCurrentPage(1);
    };
    // Search CCF Logic End

    // Get CCF Control Api Logic Start
    const [ccfControls, setCcfControls] = useState({});
    const getCCFControls = async () => {
        try {
            const apiResponse = await getCCFControlsApi();
            if (apiResponse.status === 200) {
                setCcfControls(apiResponse.data);
            }
        } catch (error) {
            console.log(error);
        }
    }
    // Get CCF Control Api Logic End
    const transformDataToProjects = (data) => {
        const bgColors = ['danger', 'info', 'warning', 'success'];
        return Object.keys(data).map((key, index) => ({
            id: index + 1,
            bg: bgColors[index % bgColors.length],
            subItem: data[key]
                .filter(item => item)
                .map((version, subIndex) => ({
                    iconClass: bgColors[index % bgColors.length],
                    id: subIndex + 1,
                    version: version
                })),
            title: key.replace(/([a-z])([A-Z])/g, '$1 $2')
        }));
    };

    const ccfControlsValue = transformDataToProjects(ccfControls);

    //  Filter CCF All Inscope Outscope Logic Start
    const handleFilterClick = (filter) => {
        setLoading(true);
        setActiveFilter(filter);
        setCurrentPage(1);
        if (filter === 'All') {
            setFilteredData(otherComplianceData);
            setLoading(false);
        } else if (filter === 'In-Scope') {
            setFilteredData(otherComplianceData.filter(item => item.applicable === true));
            setLoading(false);
        } else if (filter === 'Out-Scope') {
            setFilteredData(otherComplianceData.filter(item => item.applicable === false));
            setLoading(false);
        }
    };
    //  Filter CCF All Inscope Outscope Logic End

    const TruncatedText = ({ text, wordLimit = 50 }) => {
        const words = text.split(' ');
        const truncatedText = words.slice(0, wordLimit).join(' ');
        const [isExpanded, setIsExpanded] = useState(false);

        return (
            <div>
                <div className="text-muted job-description" style={{ fontSize: '.7rem', textAlign: 'justify' }}>
                    {isExpanded ? text : `${truncatedText}${words.length > wordLimit ? '...' : ''}`}
                    {words.length > wordLimit && (
                        <button onClick={() => setIsExpanded(!isExpanded)} style={{ border: 'none', background: 'none', color: '#007bff', cursor: 'pointer', padding: '0' }}>
                            {isExpanded ? 'Read Less' : 'Read More'}
                        </button>
                    )}
                </div>
            </div>
        );
    };

    // Applicable or not
    const handleCheckboxChange = async (checked, id) => {

        const data = {
            pcid,
            craid: id,
            applicable: checked,
        };

        const apiResponse = await updateApplicableChangeAPI(data);
        if (apiResponse.status === 200) {
            toast.success(apiResponse.data);
        }
        const updatedData = otherComplianceData.map((data) =>
            data.id === id ? { ...data, applicable: checked } : data
        );
        setOtherComplianceData(updatedData);
    };



    useEffect(() => {
        setLoading(true);
        getCCFControls();
        if (otherComplianceData.length !== 0) {
            setLoading(false);
            setFilteredData(otherComplianceData);
        }
    }, [otherComplianceData]);

    return (
        <>
            {
                otherComplianceTabName && otherComplianceTabName.map((item, index) => (
                    <TabPane tabId={item.title} key={index}>
                        <Row>
                            <Col xxl={2} xl={3} lg={3} className='pe-1'>
                                <Card>
                                    <CardBody className='projectTaskCCFDataControls p-2'>
                                        <SimpleBar className="px-4 mx-n4">
                                            <ul className="to-do-menu list-unstyled" id="projectlist-data">
                                                <li>
                                                    <Link
                                                        to="#"
                                                        className="nav-link fs-11 d-flex align-items-center justify-content-between"
                                                        onClick={() => handleFilterCCFFromControls(item.title)}>
                                                        <div className='d-flex'>
                                                            <span className={"icon me-2 bg-" + 'danger' + "-subtle" + " text-" + 'danger'}>
                                                                <i className='bx bxs-compass'></i>
                                                            </span>
                                                            All Control
                                                        </div>
                                                        <i className='ri-arrow-right-s-line'></i>
                                                    </Link>
                                                </li>
                                                {ccfControlsValue
                                                    .filter((item) => item.title !== "Control Title")
                                                    .map((item) => (
                                                        <li key={item.id}>
                                                            <Link
                                                                to="#"
                                                                className="nav-link fs-11 d-flex align-items-center"
                                                                id={"todos" + item.id}
                                                                onClick={() => toggleDropdownForOtherControls(item.id)}
                                                            >
                                                                <div className='d-flex justify-content-between w-100'>
                                                                    <div className='d-flex'>
                                                                        <span className={"icon me-2 bg-" + item.bg + "-subtle" + " text-" + item.bg}>
                                                                            <i className='bx bxs-compass'></i>
                                                                        </span>
                                                                        {item.title}
                                                                    </div>
                                                                    <i className={openDropdownForOtherControls === item.id ? "ri-arrow-down-s-line" : "ri-arrow-right-s-line"}></i>
                                                                </div>
                                                            </Link>
                                                            <UncontrolledCollapse isOpen={openDropdownForOtherControls === item.id} toggler={"#todos" + item.id}>
                                                                <ul className="mb-0 sub-menu control-list list-unstyled list-unstyled ps-1 vstack gap-2 mb-2">
                                                                    {item.subItem.map((subItem) => (
                                                                        <li key={subItem.id}>
                                                                            <Link
                                                                                to="#"
                                                                                style={{ fontSize: '.7rem', display: 'flex', position: 'relative' }}
                                                                                onClick={() => handleFilterCCFFromControls(subItem, item.title)}
                                                                            >
                                                                                <i className={"ri-stop-mini-fill align-middle fs-10 me-1 text-" + subItem.iconClass} style={{ position: 'absolute', top: '0.05rem' }}></i>
                                                                                <div className='ps-3'>{subItem.version}</div>
                                                                            </Link>
                                                                        </li>
                                                                    ))}
                                                                </ul>
                                                            </UncontrolledCollapse>
                                                        </li>
                                                    ))}


                                            </ul>
                                        </SimpleBar>
                                    </CardBody>
                                </Card>
                            </Col>

                            <Col xxl={10} xl={9} lg={9} className='ps-1 pe-1'>
                                <Card>

                                    <CardBody>

                                        <CardHeader className='p-0 pb-2 d-flex align-items-start'>
                                            <div className="filters d-flex">
                                                <div
                                                    className={`filter d-flex me-2 ${activeFilter === 'All' ? 'active' : ''}`}
                                                    onClick={() => handleFilterClick('All')}
                                                >
                                                    <div className="icon me-2">
                                                        <i className="ri-sound-module-line"></i>
                                                    </div>
                                                    All
                                                </div>
                                                <div
                                                    className={`filter d-flex me-3 ${activeFilter === 'In-Scope' ? 'active' : ''}`}
                                                    onClick={() => handleFilterClick('In-Scope')}
                                                >
                                                    <div className="icon me-2">
                                                        <i className="ri-sound-module-line"></i>
                                                    </div>
                                                    In-Scope
                                                </div>
                                                <div
                                                    className={`filter d-flex ${activeFilter === 'Out-Scope' ? 'active' : ''}`}
                                                    onClick={() => handleFilterClick('Out-Scope')}
                                                >
                                                    <div className="icon me-2">
                                                        <i className="ri-sound-module-line"></i>
                                                    </div>
                                                    Out-Scope
                                                </div>
                                            </div>

                                            <div className="search app-search p-0 ms-3">
                                                <div className="position-relative">
                                                    <Input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Search..."
                                                        value={searchQuery}
                                                        onChange={handleSearchChange}
                                                    />
                                                    <span className="mdi mdi-magnify search-widget-icon"></span>
                                                </div>
                                            </div>
                                        </CardHeader>

                                        <div className='projectTaskCCFData'>

                                            {loading ? (
                                                <div className="text-center" style={{ height: '30vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                    <div>
                                                        <Spinner color="primary" style={{ opacity: '.2' }} />
                                                        <p style={{ opacity: '.4' }}>Loading Data...</p>
                                                    </div>
                                                </div>
                                            ) : (
                                                (paginateCCFData && paginateCCFData.length > 0) ? (
                                                    paginateCCFData.map((data, key) => (
                                                        <div key={key} className='py-2 mb-2' style={{ borderBottom: '2px solid #e9ebec' }}>
                                                            <div className="d-flex mb-1">
                                                                <div className="avatar-xxs">
                                                                    <div className="avatar-title bg-danger-subtle rounded">
                                                                        <i className="bx bxs-compass text-danger"></i>
                                                                    </div>
                                                                </div>
                                                                <div className="ms-3 flex-grow-1">
                                                                    <div className='d-flex align-items-center'>
                                                                        <h5 className="job-title fs-12 mb-2 me-3" style={{ cursor: 'pointer', textDecoration: 'underline' }}>{data.cvp.certificationIndex}</h5>
                                                                        {role === "AUDITOR" && (
                                                                            <input
                                                                                type="checkbox"
                                                                                checked={data.applicable || false}
                                                                                onChange={(e) => handleCheckboxChange(e.target.checked, data.id)}
                                                                            />
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='d-flex align-items-center flex-wrap mb-1'>
                                                                {
                                                                    data.cvp.ccfNameList.map((item, key) => (
                                                                        <div className='bg-light rounded p-0 fs-10 cursor-pointer me-3 mb-1' onClick={() => toggleEvidence(item.id, pid, item.ccf)} key={key}>
                                                                            <i className="ri-honour-line align-bottom me-0"></i>{" "}
                                                                            <span className="job-type">
                                                                                {item.ccf}
                                                                            </span>
                                                                        </div>
                                                                    ))
                                                                }
                                                            </div>
                                                            <TruncatedText text={data.cvp.controlWording} wordLimit={30} />
                                                        </div>
                                                    ))
                                                ) : (
                                                    <div className="text-center py-3">
                                                        <h5>No Data Available</h5>
                                                    </div>
                                                )

                                            )}

                                        </div>

                                    </CardBody>

                                    <div className='d-flex justify-content-between align-items-center' style={{ borderTop: '1px solid #ccc', padding: '.5rem' }}>
                                        <p className="mb-0 ps-3">Current control is : <span className="fw-bold">{currentCCFFilterControlApply === "" ? "All Control" : currentCCFFilterControlApply}</span></p>
                                        {/* Pagination controls */}
                                        <div className="pagination-controls">
                                            <Button color='primary' size='sm' onClick={handlePreviousPage} disabled={currentPage === 1}>
                                                Previous
                                            </Button>
                                            <span> Page {currentPage} of {Math.max(1, Math.ceil(filteredData.length / itemsPerPage))} </span>
                                            <Button color='primary' size='sm' onClick={handleNextPage} disabled={currentPage >= Math.ceil(filteredData.length / itemsPerPage)}>
                                                Next
                                            </Button>
                                        </div>
                                        <p className="mb-0 pe-3">Showing <span className="fw-bold">{filteredData.length}</span> {currentOtherComplianceNameShow} Points</p>
                                    </div>

                                </Card>
                            </Col>
                        </Row>
                    </TabPane>
                ))
            }
        </>
    )
}