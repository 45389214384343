import React, { useState } from 'react';
import { useReactTable, getCoreRowModel, flexRender, getPaginationRowModel, getSortedRowModel, getFilteredRowModel } from '@tanstack/react-table';
import { Card, CardBody, CardHeader, Col, Pagination, PaginationItem, PaginationLink, Row, Table } from 'reactstrap';
import ExportCSVModal from '../../Components/Common/ExportCSVModal';

const BasicTable = ({ data, columns, customPageSize, onclickHandlerAddUser }) => {
    const [sorting, setSorting] = useState([]);
    const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: customPageSize });
    const [filtering, setFiltering] = useState('');

    const table = useReactTable({
        data,
        columns,
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        state: { sorting, pagination, globalFilter: filtering },
        onSortingChange: setSorting,
        onPaginationChange: setPagination,
        onGlobalFilterChange: setFiltering,
    });

    const totalPages = table.getPageCount();
    const currentPage = table.getState().pagination.pageIndex;
    const pageNumbersToShow = [];

    const startPage = Math.max(0, currentPage - 1);
    const endPage = Math.min(totalPages - 1, currentPage + 1);

    for (let i = 0; i < totalPages; i++) {
        if (i === 0 || i === 1 || (i >= startPage && i <= endPage) || i === totalPages - 1) {
            pageNumbersToShow.push(i);
        }
    }

    // Calculate the current row display range
    const currentPageSize = table.getState().pagination.pageSize;
    const totalDataCount = data.length;
    const currentRowStart = currentPage * currentPageSize + 1;
    const currentRowEnd = Math.min((currentPage + 1) * currentPageSize, totalDataCount);

    // Export To CSV
    const [isExportCSV, setIsExportCSV] = useState(false);

    return (
        <>

            <ExportCSVModal
                show={isExportCSV}
                onCloseClick={() => setIsExportCSV(false)}
                data={data}
            />
            <Card>
                <CardHeader className="border-0 py-2">
                    <Row className="g-4 align-items-center">
                        <div className="col-sm">
                            <div>
                                <h5 className="card-title mb-0">Users List</h5>
                            </div>
                        </div>
                        <div className="col-sm-auto">
                            <div className="d-flex">
                                <div className='me-2'>
                                    <input className='form-control' type="text" placeholder='Search...' value={filtering} onChange={(e) => setFiltering(e.target.value)} style={{ height: '37px', borderRadius: '.2rem' }} />
                                </div>
                                <div>
                                    <button type="button" className="btn btn-success add-btn me-2" id="create-btn" onClick={onclickHandlerAddUser}>
                                        <i className="ri-add-line align-bottom me-1"></i>
                                        Add User
                                    </button>
                                    {/* <button type="button" className="btn btn-info" onClick={() => setIsExportCSV(true)}>
                                        <i className="ri-file-download-line align-bottom me-1"></i>{" "}
                                        Export
                                    </button> */}
                                </div>
                            </div>
                        </div>
                    </Row>
                </CardHeader>
                <CardBody className="border border-dashed border-end-0 border-start-0">
                    <div className="scroll-y-table">
                        <Table className='table' striped bordered>
                            <thead>
                                {table.getHeaderGroups().map(headerGroup => (
                                    <tr key={headerGroup.id}>
                                        {headerGroup.headers.map(header => (
                                            <th key={header.id} onClick={header.column.getToggleSortingHandler()}>
                                                {header.placeholderId ? null : flexRender(header.column.columnDef.header, header.getContext())}
                                                {header.column.getIsSorted() ? (
                                                    header.column.getIsSorted() === 'asc' ?
                                                        <i className="bx bxs-up-arrow ms-2"></i> :
                                                        <i className="bx bxs-down-arrow ms-2"></i>
                                                ) : null}
                                            </th>
                                        ))}
                                    </tr>
                                ))}
                            </thead>
                            <tbody>
                                {table.getRowModel().rows.length > 0 ? (
                                    table.getRowModel().rows.map(row => (
                                        <tr key={row.id}>
                                            {row.getVisibleCells().map(cell => (
                                                <td key={cell.id}>
                                                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                                </td>
                                            ))}
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan={columns.length} className="text-center">
                                            No data available
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </Table>
                    </div>

                    <div className='mt-2' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <div className="text-muted fs-12">
                            Showing <span className="fw-semibold">{currentRowEnd}</span> of <span className="fw-semibold">{totalDataCount}</span> Results
                        </div>

                        <Pagination size='sm'>
                            <PaginationItem disabled={!table.getCanPreviousPage()}>
                                <PaginationLink onClick={() => table.setPageIndex(0)}>1</PaginationLink>
                            </PaginationItem>
                            <PaginationItem disabled={!table.getCanPreviousPage()}>
                                <PaginationLink onClick={table.previousPage}>← Prev</PaginationLink>
                            </PaginationItem>

                            {pageNumbersToShow.map(page => (
                                <PaginationItem key={page} active={page === currentPage}>
                                    <PaginationLink onClick={() => table.setPageIndex(page)}>{page + 1}</PaginationLink>
                                </PaginationItem>
                            ))}

                            <PaginationItem disabled={!table.getCanNextPage()}>
                                <PaginationLink onClick={table.nextPage}>Next →</PaginationLink>
                            </PaginationItem>
                            <PaginationItem disabled={!table.getCanNextPage()}>
                                <PaginationLink onClick={() => table.setPageIndex(totalPages - 1)}>{totalPages}</PaginationLink>
                            </PaginationItem>
                        </Pagination>
                    </div>
                </CardBody>
            </Card>
        </>
    );
};

export default BasicTable;