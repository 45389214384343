import React, { useEffect, useState } from 'react'
import { Alert, Button, Card, CardBody, CardHeader, Col, Container, DropdownItem, DropdownMenu, DropdownToggle, Form, Input, ListGroup, ListGroupItem, Nav, NavItem, NavLink, Offcanvas, OffcanvasBody, OffcanvasHeader, Row, Spinner, TabContent, TabPane, UncontrolledCollapse, UncontrolledDropdown, } from 'reactstrap'
import BreadCrumb from '../../Components/Common/BreadCrumb'
import SimpleBar from 'simplebar-react';
import { Link } from 'react-router-dom';
import classnames from "classnames";
import slack from '../../assets/images/brands/slack.png';
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import { map } from "lodash";
import Cookies from 'js-cookie';
import { addCommentApi, deleteEvidenceApi, downloadEvidenceApi, getCCFControlsApi, getCetificationComments, getCetificationEvidence, getComplianceListApi, getMappedPointWithCCF, getProjectTaskWithCCF, getProjectTaskWithCCFFilterByControlApi, getProjectTaskWithOtherCertification, getProjectTaskWithOtherCertificationFilterByControlApi, getReferencesApi, uploadEvidenceApi } from '../../apiConfig/apiCall';
import { toast } from 'react-toastify';
import ComplianceCanvas from '../../Components/ProjectTaskComponents/ComplianceCanvas';

const Testing = () => {

    const CLIENT_ID = Cookies.get('CLIENT_ID');
    const currentUser = Cookies.get('user');

    const [loading, setLoading] = useState(false);

    const [openDropdown, setOpenDropdown] = useState(null);
    const [arrowNavTab, setarrowNavTab] = useState("CCF");
    const [activeFilter, setActiveFilter] = useState('All');
    const [isRight, setIsRight] = useState(false);
    const [topBorderTab, settopBorderTab] = useState("1");
    const [activeTab, setActiveTab] = useState("");
    const [complianceOffCanvas, setComplianceOffCanvas] = useState(false);
    const [pid, setPid] = useState('');
    const [defaultComplianceTabName, setDefaultComplianceTabName] = useState('');
    const [otherComplianceTabName, setOtherComplianceTabName] = useState('');

    const [ccfData, setCcfData] = useState([]);
    const [otherComplianceData, setOtherComplianceData] = useState([]);

    const [evidenceFile, setEvidenceFile] = useState([]);
    const [idForFileUpload, setIdForFileUpload] = useState();

    const [idForDeleteFile, setIdForDeleteFile] = useState();

    const [activityCommentsTab, setActivityCommentsTab] = useState([]);
    const [inputMessage, setInputMessage] = useState('');

    const [referencesData, setReferencesData] = useState([]);
    const [messages, setMessages] = useState([]);

    const [mappedPointsData, setMappedPointsData] = useState([]);
    const [mappedComplianceName, setMappedComplianceName] = useState('');

    const [ccfNameTitle, setCCFNameTitle] = useState('');
    const [ccfControls, setCcfControls] = useState({});

    const [projectStatus, setProjectStatus] = useState('Active');

    const [searchQuery, setSearchQuery] = useState('');

    const [filteredData, setFilteredData] = useState(ccfData);


    const toggleDropdown = (id) => {
        setOpenDropdown(openDropdown === id ? null : id);
    };

    const transformDataToProjects = (data) => {
        const bgColors = ['danger', 'info', 'warning', 'success'];
        return Object.keys(data).map((key, index) => ({
            id: index + 1,
            bg: bgColors[index % bgColors.length],
            subItem: data[key]
                .filter(item => item)
                .map((version, subIndex) => ({
                    iconClass: bgColors[index % bgColors.length],
                    id: subIndex + 1,
                    version: version
                })),
            title: key.replace(/([a-z])([A-Z])/g, '$1 $2')
        }));
    };

    const projects = transformDataToProjects(ccfControls);


    const TruncatedText = ({ text, wordLimit = 50 }) => {
        const words = text.split(' ');
        const truncatedText = words.slice(0, wordLimit).join(' ');
        const [isExpanded, setIsExpanded] = useState(false);

        return (
            <div>
                <div className="text-muted job-description" style={{ fontSize: '.7rem', textAlign: 'justify' }}>
                    {isExpanded ? text : `${truncatedText}${words.length > wordLimit ? '...' : ''}`}
                    {words.length > wordLimit && (
                        <button onClick={() => setIsExpanded(!isExpanded)} style={{ border: 'none', background: 'none', color: '#007bff', cursor: 'pointer', padding: '0' }}>
                            {isExpanded ? 'Read Less' : 'Read More'}
                        </button>
                    )}
                </div>
            </div>
        );
    };

    const arrowNavToggle = async (tab) => {
        if (arrowNavTab !== tab) {
            setarrowNavTab(tab);
            getOtherComplianceData(CLIENT_ID, tab);
        }
    };


    const toggleRightCanvas = () => {
        setIsRight(!isRight);
    };
    const topBordertoggle = (tab) => {
        if (topBorderTab !== tab) settopBorderTab(tab);
    }
    const toggle = (tab) => {
        if (activeTab !== tab) setActiveTab(tab);
    };
    const toggleComplianceOffCanvas = () => {
        setComplianceOffCanvas(!complianceOffCanvas);
    };

    // Get Compliance List APi Logic Start
    const getComplianceList = async () => {
        try {
            const clientCompanyId = Cookies.get('CLIENT_ID');
            const apiResponse = await getComplianceListApi(clientCompanyId);

            if (apiResponse.status === 200) {
                const transformedData = apiResponse.data.map(item => ({
                    id: item.id,
                    title: item.certificationType,
                    imageSrc: item.logo ? item.logo : slack
                }));

                setDefaultComplianceTabName(transformedData[0]);
                const remainingOtherCompliance = transformedData.slice(1);
                setOtherComplianceTabName(remainingOtherCompliance);
            } else {
                toast.error("Error getting compliance");
            }
        } catch (error) {
            toast.error("An error occurred while fetching compliance data");
            console.error('Error fetching compliance data:', error);
        }
    };
    // Get Compliance List APi Logic Start

    // Get CCF Data Api Logic Start
    const getProjectTaskDetailsCompliance = async (CLIENT_ID) => {
        try {
            const apiResponse = await getProjectTaskWithCCF(CLIENT_ID);
            if (apiResponse.status === 200) {
                setPid(apiResponse.data.id);
                setCcfData(apiResponse.data.requirementApproach);
            }
        } catch (error) {
            console.log(error);
        }
    }
    // Get CCF Data Api Logic Start


    // Get CCF Control Api Logic Start
    const getCCFControls = async () => {
        try {
            const apiResponse = await getCCFControlsApi();
            if (apiResponse.status === 200) {
                setCcfControls(apiResponse.data);
            }
        } catch (error) {
            console.log(error);
        }
    }
    // Get CCF Control Api Logic End

    // Handle CCF Controls APi Logic Start
    const [currentCCFFilterControlApply, setCurrentCCFFilterControlApply] = useState('');
    const handleFilterCCFFromControls = async (controlName, controlValue) => {
        try {
            setLoading(true);
            const apiResponse = await getProjectTaskWithCCFFilterByControlApi(CLIENT_ID, controlName, controlValue.version);
            if (apiResponse.status === 200) {
                setCcfData(apiResponse.data.requirementApproach);
                setCurrentCCFFilterControlApply(controlValue.version);
                setCurrentPage(1);
            }
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };
    // Handle CCF Controls APi Logic Start


    // Handle Pagintaion Logic FOr CCF
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 5;
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;

    const filteredCCFData = filteredData.filter(data =>
        data.controlReference.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const paginateCCFData = filteredCCFData.slice(indexOfFirstItem, indexOfLastItem);

    const handleNextPage = () => {
        if (currentPage < Math.ceil(ccfData.length / itemsPerPage)) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };


    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
        setCurrentPage(1);
    };

    const handleFilterClick = (filter) => {
        setActiveFilter(filter);
        setCurrentPage(1);
        if (filter === 'All') {
            setFilteredData(ccfData);
        } else if (filter === 'In-Scope') {
            setFilteredData(ccfData.filter(item => item.applicable === true));
        } else if (filter === 'Out-Scope') {
            setFilteredData(ccfData.filter(item => item.applicable === false));
        }
    };


    // Handle CCF Controls APi Logic Start
    const handleFilterOtherComplianceFromControls = async (controlName, controlValue) => {
        try {

            console.log(CLIENT_ID, arrowNavTab, controlName, controlValue.version)

            const apiResponse = await getProjectTaskWithOtherCertificationFilterByControlApi(CLIENT_ID, arrowNavTab, controlName, controlValue.version);
            if (apiResponse.status === 200) {
                setOtherComplianceData(apiResponse.data.projectCertification[0].cra);
            }
        } catch (error) {
            console.log(error);
        }
    };
    // Handle CCF Controls APi Logic Start


    // Get Other Compliance Data APi Logic Start
    const getOtherComplianceData = async (CLIENT_ID, certificationName) => {
        try {
            const apiResponse = await getProjectTaskWithOtherCertification(CLIENT_ID, certificationName);
            if (apiResponse.status === 200) {
                setOtherComplianceData(apiResponse.data.projectCertification[0].cra);
            }
        } catch (error) {
            console.log(error);
        }
    }
    // Get Other Compliance Data APi Logic Start


    const toggleCCFEvidence = async (id, pid, nameOfCCF) => {
        try {
            setCCFNameTitle(nameOfCCF);
            setIsRight(true);
            setIdForDeleteFile(id);
            setIdForFileUpload(id);
            getCetificationCommentsData(id, pid);
            getReferences(id, pid)
            const apiResponse = await getCetificationEvidence(id, pid);
            setEvidenceFile(apiResponse.data);
        } catch (error) {
            console.log(error);
        }
    }

    const deleteEvidence = async (id, pid, filename) => {
        try {
            const data = new FormData();
            data.append("id", id);
            data.append("pid", pid);
            data.append("filename", filename);
            const apiResponse = await deleteEvidenceApi(data);
            if (apiResponse.status === 200) {
                toast.success(apiResponse.data);
            }
        } catch (error) {
            console.log(error);
        }
    }

    const downloadEvidence = async (id, pid, filename) => {
        try {
            const filePath = filename;
            const fileNameFormatDownload = filename.split('/').pop()
            const encodedFilePath = encodeURIComponent(filePath);
            const apiResponse = await downloadEvidenceApi(id, pid, encodedFilePath, 'blob');
            if (apiResponse.status === 200) {
                const blob = new Blob([apiResponse.data]);
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', fileNameFormatDownload);
                document.body.appendChild(link);
                link.click();
                window.URL.revokeObjectURL(url);
                link.remove();
            }
        } catch (error) {
            console.log('Error while downloading file:', error);
        }
    };

    const getCetificationCommentsData = async (id, pid) => {
        try {
            const apiResponse = await getCetificationComments(id, pid);
            if (apiResponse.status === 200) {
                setMessages(apiResponse.data);
                const commentTabs = Object.keys(apiResponse.data);
                setActivityCommentsTab(commentTabs);
                if (commentTabs.length > 0) {
                    setActiveTab(commentTabs[0]);
                }
            }
        } catch (error) {
            console.log(error);
        }
    };

    const getReferences = async (id, pid) => {
        try {
            const apiResponse = await getReferencesApi(id, pid);
            if (apiResponse.status === 200) {
                setReferencesData(apiResponse.data);
            }
        } catch (error) {
            console.log(error);
        }
    }

    // Upload Evidence
    const [selectedFile, setSelectedFile] = useState(null);
    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
    };

    const uploadEvidence = async (event) => {
        event.preventDefault();

        if (!selectedFile) {
            console.log('Please select a file to upload');
            return;
        }

        const data = new FormData();
        data.append('file', selectedFile);
        data.append('id', idForFileUpload);
        data.append('pid', pid);

        console.log('file', selectedFile);
        console.log('id', idForFileUpload);
        console.log('pid', pid);

        try {
            const apiResponse = await uploadEvidenceApi(data);
            if (apiResponse.status === 200) {
                toast.success('File uploaded successfully');
            } else if (apiResponse.status === 405) {
                toast.error(apiResponse.data);
            }
        } catch (error) {
            console.error('Error uploading file:', error);
        }
    };


    //  Add Comment 
    const handleInputChange = (e) => {
        setInputMessage(e.target.value);
    };

    const handleSendMessage = (e) => {
        e.preventDefault();
        if (inputMessage.trim()) {
            sendMessage(idForDeleteFile, pid, inputMessage, activeTab);
            setInputMessage('');
        }
    };

    const sendMessage = async (idForDeleteFile, pid, comment, certificationName) => {
        try {
            const data = new FormData();
            data.append('id', idForDeleteFile);
            data.append('pid', pid);
            data.append('comment', comment);
            data.append('certificationName', certificationName);
            const apiResponse = await addCommentApi(data);
            if (apiResponse.status === 200) {
                toast.success("Message send successfully");
            }
        } catch (error) {
            console.log(error);
        }
    };

    // Toggle Mapped Points Api Logic
    const toggleMappedPointWithCCF = async (controlReference, mappedCompliance) => {

        setMappedComplianceName(mappedCompliance)
        setComplianceOffCanvas(!complianceOffCanvas);
        try {
            const apiResponse = await getMappedPointWithCCF(controlReference, mappedCompliance);
            if (apiResponse.status === 200) {
                setMappedPointsData(apiResponse.data);
            }
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            await getProjectTaskDetailsCompliance(CLIENT_ID);
            setLoading(false);
        };
        fetchData();
        getComplianceList();
        getCCFControls();
    }, []);


    return (
        <>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title={'Project Task'} pageTitle={'Dashboard'} />
                    <Row>
                        <Col lg={12} className='pe-1 ps-1'>

                            <Nav pills className="nav nav-pills arrow-navtabs nav-success bg-light mb-2">

                                <NavItem className='me-2'>
                                    <NavLink
                                        style={{
                                            cursor: "pointer",
                                            padding: '.1rem .4rem',
                                            backgroundColor: arrowNavTab === defaultComplianceTabName.title ? '#0ab39c' : 'white'
                                        }}
                                        className={classnames({ active: arrowNavTab === defaultComplianceTabName.title })}
                                        onClick={() => { arrowNavToggle(defaultComplianceTabName.title); }}
                                    >
                                        <div className="d-flex align-items-center">
                                            <h4 className="fs-12 mb-0" style={{ color: arrowNavTab === defaultComplianceTabName.title ? 'white' : 'black' }}>{defaultComplianceTabName.title}</h4>
                                            <div className="ms-3 avatar-xxs bg-white rounded-circle p-1 d-flex justify-content-center align-items-center">
                                                <img src={defaultComplianceTabName.imageSrc} alt='...' style={{ width: '100%', height: '100%' }} />
                                            </div>
                                        </div>
                                    </NavLink>
                                </NavItem>

                                {
                                    otherComplianceTabName && otherComplianceTabName.map((item, index) => (
                                        <NavItem className='me-2' key={index}>
                                            <NavLink
                                                style={{
                                                    cursor: "pointer",
                                                    padding: '.1rem .4rem',
                                                    backgroundColor: arrowNavTab === item.title ? '#0ab39c' : 'white'
                                                }}
                                                className={classnames({ active: arrowNavTab === item.title })}
                                                onClick={() => { arrowNavToggle(item.title); }}
                                            >
                                                <div className="d-flex align-items-center">
                                                    <h4 className="fs-12 mb-0" style={{ color: arrowNavTab === item.title ? 'white' : 'black' }}>{item.title}</h4>
                                                    <div className="ms-3 avatar-xxs bg-white rounded-circle p-1 d-flex justify-content-center align-items-center">
                                                        <img src={item.imageSrc} alt='...' style={{ width: '100%', height: '100%' }} />
                                                    </div>
                                                </div>
                                            </NavLink>
                                        </NavItem>
                                    ))
                                }

                            </Nav>

                            <TabContent activeTab={arrowNavTab} className="text-muted" >

                                <TabPane tabId={defaultComplianceTabName.title}>
                                    <Row>
                                        <Col xxl={2} xl={3} lg={3} className='pe-1'>
                                            <Card>
                                                <CardBody className='projectTaskCCFDataControls p-2'>
                                                    <SimpleBar className="px-4 mx-n4">
                                                        <ul className="to-do-menu list-unstyled" id="projectlist-data">
                                                            <li>
                                                                <Link
                                                                    to="#"
                                                                    className="nav-link fs-11 d-flex align-items-center justify-content-between"
                                                                >
                                                                    <div className='d-flex'>
                                                                        <span className={"icon me-2 bg-" + 'danger' + "-subtle" + " text-" + 'danger'}>
                                                                            <i className='bx bxs-compass'></i>
                                                                        </span>
                                                                        All Control
                                                                    </div>
                                                                    <i className='ri-arrow-right-s-line'></i>
                                                                </Link>
                                                            </li>
                                                            {projects.map((item) => (
                                                                <li key={item.id}>
                                                                    <Link
                                                                        to="#"
                                                                        className="nav-link fs-11 d-flex align-items-center"
                                                                        id={"todos" + item.id}
                                                                        onClick={() => toggleDropdown(item.id)}
                                                                    >
                                                                        <div className='d-flex justify-content-between w-100'>
                                                                            <div className='d-flex'>
                                                                                <span className={"icon me-2 bg-" + item.bg + "-subtle" + " text-" + item.bg}>
                                                                                    <i className='bx bxs-compass'></i>
                                                                                </span>
                                                                                {item.title}
                                                                            </div>
                                                                            <i className={openDropdown === item.id ? "ri-arrow-down-s-line" : "ri-arrow-right-s-line"}></i>
                                                                        </div>
                                                                    </Link>
                                                                    <UncontrolledCollapse isOpen={openDropdown === item.id} toggler={"#todos" + item.id}>
                                                                        <ul className="mb-0 sub-menu control-list list-unstyled list-unstyled ps-1 vstack gap-2 mb-2">
                                                                            {item.subItem.map((subItem) => (
                                                                                <li key={subItem.id}>
                                                                                    <Link
                                                                                        to="#"
                                                                                        style={{ fontSize: '.7rem', display: 'flex', position: 'relative' }}
                                                                                        onClick={() => handleFilterCCFFromControls(item.title, subItem)}
                                                                                    >
                                                                                        <i className={"ri-stop-mini-fill align-middle fs-10 me-1 text-" + subItem.iconClass} style={{ position: 'absolute', top: '0.05rem' }}></i>
                                                                                        <div className='ps-3'>{subItem.version}</div>
                                                                                    </Link>
                                                                                </li>
                                                                            ))}
                                                                        </ul>
                                                                    </UncontrolledCollapse>
                                                                </li>
                                                            ))}


                                                        </ul>
                                                    </SimpleBar>
                                                </CardBody>
                                            </Card>
                                        </Col>

                                        <Col xxl={10} xl={9} lg={9} className='ps-1 pe-1'>
                                            <Card>

                                                <CardBody>
                                                    <CardHeader className='p-0 pb-2 d-flex align-items-start'>
                                                        <div className="filters d-flex">
                                                            <div
                                                                className={`filter d-flex me-2 ${activeFilter === 'All' ? 'active' : ''}`}
                                                                onClick={() => handleFilterClick('All')}
                                                            >
                                                                <div className="icon me-2">
                                                                    <i className="ri-sound-module-line"></i>
                                                                </div>
                                                                All
                                                            </div>
                                                            <div
                                                                className={`filter d-flex me-3 ${activeFilter === 'In-Scope' ? 'active' : ''}`}
                                                                onClick={() => handleFilterClick('In-Scope')}
                                                            >
                                                                <div className="icon me-2">
                                                                    <i className="ri-sound-module-line"></i>
                                                                </div>
                                                                In-Scope
                                                            </div>
                                                            <div
                                                                className={`filter d-flex ${activeFilter === 'Out-Scope' ? 'active' : ''}`}
                                                                onClick={() => handleFilterClick('Out-Scope')}
                                                            >
                                                                <div className="icon me-2">
                                                                    <i className="ri-sound-module-line"></i>
                                                                </div>
                                                                Out-Scope
                                                            </div>
                                                        </div>

                                                        <div className="search app-search p-0 ms-3">
                                                            <div className="position-relative">
                                                                <Input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder="Search..."
                                                                    value={searchQuery}
                                                                    onChange={handleSearchChange}
                                                                />
                                                                <span className="mdi mdi-magnify search-widget-icon"></span>
                                                            </div>
                                                        </div>
                                                    </CardHeader>

                                                    <div className='projectTaskCCFData'>

                                                        {loading ? (
                                                            <div className="text-center" style={{ height: '30vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                                <div>
                                                                    <Spinner color="primary" style={{ opacity: '.2' }} />
                                                                    <p style={{ opacity: '.4' }}>Loading Data...</p>
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            (paginateCCFData || []).map((data, key) => (
                                                                <div key={key} className='py-2 mb-2' style={{ borderBottom: '2px solid #e9ebec', position: 'relative' }}>
                                                                    <div className="d-flex mb-1">
                                                                        <div className="avatar-xxs">
                                                                            <div className="avatar-title bg-danger-subtle rounded">
                                                                                <i className="bx bxs-compass text-danger"></i>
                                                                            </div>
                                                                        </div>
                                                                        <div className="ms-3 flex-grow-1">
                                                                            <div className='d-flex align-items-center'>
                                                                                <h5 className="job-title fs-12 mb-0 me-3" onClick={() => toggleCCFEvidence(data.id, pid, data.controlReference)} style={{ cursor: 'pointer', textDecoration: 'underline' }}>{data.controlReference}</h5>
                                                                                <div className='d-flex align-items-center flex-wrap gap-3'>
                                                                                    {
                                                                                        data.certificationStatus.map((item, index) => {
                                                                                            const title = Object.keys(item)[0];
                                                                                            const status = item[title].status;
                                                                                            return (
                                                                                                <span className={`cursor-pointer badge
                                                                                                ${status === 'PROCESSING'
                                                                                                        ? 'bg-warning-subtle text-warning'
                                                                                                        : status === 'PENDING'
                                                                                                            ? 'bg-info-subtle text-info'
                                                                                                            : status === 'COMPLETED'
                                                                                                                ? 'bg-success-subtle text-success'
                                                                                                                : status === 'REJECTED'
                                                                                                                    ? 'bg-danger-subtle text-danger'
                                                                                                                    : 'bg-light-subtle text-light'
                                                                                                    }`}
                                                                                                    key={index}
                                                                                                    onClick={() => toggleMappedPointWithCCF(data.controlReference, title)}
                                                                                                >
                                                                                                    <i className="ri-honour-line align-bottom me-1"></i>
                                                                                                    {title}
                                                                                                </span>
                                                                                            );
                                                                                        })
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div style={{ position: 'absolute', right: '0', top: '0', fontSize: '.6rem', background: '#1563b1', color: '#fff', padding: '.1rem .3rem', borderRadius: '.2rem' }}>{data.status}</div>

                                                                    <p className='mb-0 fs-12 text-success'>Department - {data.certificationVersionCCFPoints.department}</p>

                                                                    <TruncatedText text={data.certificationVersionCCFPoints.controlWording} wordLimit={30} />
                                                                </div>
                                                            ))
                                                        )}

                                                    </div>

                                                </CardBody>
                                                <div className='d-flex justify-content-between align-items-center' style={{ borderTop: '1px solid #ccc', padding: '.5rem' }}>
                                                    <p className="mb-0 ps-3">Current control is : <span className="fw-bold">{currentCCFFilterControlApply === "" ? "All Control" : currentCCFFilterControlApply}</span></p>
                                                    {/* Pagination controls */}
                                                    <div className="pagination-controls">
                                                        <Button color='primary' size='sm' onClick={handlePreviousPage} disabled={currentPage === 1}>
                                                            Previous
                                                        </Button>
                                                        <span> Page {currentPage} of {Math.max(1, Math.ceil(filteredData.length / itemsPerPage))} </span>
                                                        <Button color='primary' size='sm' onClick={handleNextPage} disabled={currentPage >= Math.ceil(filteredData.length / itemsPerPage)}>
                                                            Next
                                                        </Button>
                                                    </div>
                                                    <p className="mb-0 pe-3">Showing <span className="fw-bold">{filteredData.length}</span> CCF</p>
                                                </div>
                                            </Card>
                                        </Col>
                                    </Row>
                                </TabPane>

                                {
                                    otherComplianceTabName && otherComplianceTabName.map((item, index) => (
                                        <TabPane tabId={item.title} key={index}>
                                            <Row>
                                                <Col xxl={2} xl={3} lg={3} className='pe-1'>
                                                    <Card>
                                                        <CardBody className='projectTaskCCFDataControls p-2'>
                                                            <SimpleBar className="px-4 mx-n4">
                                                                <ul className="to-do-menu list-unstyled" id="projectlist-data">
                                                                    <li>
                                                                        <Link
                                                                            to="#"
                                                                            className="nav-link fs-11 d-flex align-items-center justify-content-between"
                                                                        >
                                                                            <div className='d-flex'>
                                                                                <span className={"icon me-2 bg-" + 'danger' + "-subtle" + " text-" + 'danger'}>
                                                                                    <i className='bx bxs-compass'></i>
                                                                                </span>
                                                                                All Control
                                                                            </div>
                                                                            <i className='ri-arrow-right-s-line'></i>
                                                                        </Link>
                                                                    </li>
                                                                    {projects.map((item) => (
                                                                        <li key={item.id}>
                                                                            <Link
                                                                                to="#"
                                                                                className="nav-link fs-11 d-flex align-items-center"
                                                                                id={"todos" + item.id}
                                                                                onClick={() => toggleDropdown(item.id)}
                                                                            >
                                                                                <div className='d-flex justify-content-between w-100'>
                                                                                    <div className='d-flex'>
                                                                                        <span className={"icon me-2 bg-" + item.bg + "-subtle" + " text-" + item.bg}>
                                                                                            <i className='bx bxs-compass'></i>
                                                                                        </span>
                                                                                        {item.title}
                                                                                    </div>
                                                                                    <i className={openDropdown === item.id ? "ri-arrow-down-s-line" : "ri-arrow-right-s-line"}></i>
                                                                                </div>
                                                                            </Link>
                                                                            <UncontrolledCollapse isOpen={openDropdown === item.id} toggler={"#todos" + item.id}>
                                                                                <ul className="mb-0 sub-menu control-list list-unstyled list-unstyled ps-1 vstack gap-2 mb-2">
                                                                                    {item.subItem.map((subItem) => (
                                                                                        <li key={subItem.id}>
                                                                                            <Link
                                                                                                to="#"
                                                                                                style={{ fontSize: '.7rem', display: 'flex', position: 'relative' }}
                                                                                                onClick={() => handleFilterOtherComplianceFromControls(item.title, subItem)}
                                                                                            >
                                                                                                <i className={"ri-stop-mini-fill align-middle fs-10 me-1 text-" + subItem.iconClass} style={{ position: 'absolute', top: '0.05rem' }}></i>
                                                                                                <div className='ps-3'>{subItem.version}</div>
                                                                                            </Link>
                                                                                        </li>
                                                                                    ))}
                                                                                </ul>
                                                                            </UncontrolledCollapse>
                                                                        </li>
                                                                    ))}


                                                                </ul>
                                                            </SimpleBar>
                                                        </CardBody>
                                                    </Card>
                                                </Col>
                                                <Col xxl={10} xl={9} lg={9} className='ps-1 pe-1'>
                                                    <Card>

                                                        <CardBody className='projectTaskCCFData p-3' style={{ height: '70vh' }}>
                                                            <CardHeader className='p-0 pb-2 d-flex align-items-start'>
                                                                <div className="filters d-flex">
                                                                    <div
                                                                        className={`filter d-flex me-2 ${activeFilter === 'All' ? 'active' : ''}`}
                                                                        onClick={() => handleFilterClick('All')}
                                                                    >
                                                                        <div className="icon me-2">
                                                                            <i className="ri-sound-module-line"></i>
                                                                        </div>
                                                                        All
                                                                    </div>
                                                                    <div
                                                                        className={`filter d-flex me-3 ${activeFilter === 'In-Scope' ? 'active' : ''}`}
                                                                        onClick={() => handleFilterClick('In-Scope')}
                                                                    >
                                                                        <div className="icon me-2">
                                                                            <i className="ri-sound-module-line"></i>
                                                                        </div>
                                                                        In-Scope
                                                                    </div>
                                                                    <div
                                                                        className={`filter d-flex ${activeFilter === 'Out-Scope' ? 'active' : ''}`}
                                                                        onClick={() => handleFilterClick('Out-Scope')}
                                                                    >
                                                                        <div className="icon me-2">
                                                                            <i className="ri-sound-module-line"></i>
                                                                        </div>
                                                                        Out-Scope
                                                                    </div>
                                                                </div>

                                                                <div className="search app-search p-0 ms-3">
                                                                    <div className="position-relative">
                                                                        <Input type="text" className="form-control" placeholder="Search..."
                                                                            id="search-options"
                                                                        />
                                                                        <span className="mdi mdi-magnify search-widget-icon"></span>
                                                                        <span className="mdi mdi-close-circle search-widget-icon search-widget-icon-close d-none"
                                                                            id="search-close-options"></span>
                                                                    </div>
                                                                </div>
                                                            </CardHeader>

                                                            {(otherComplianceData || []).map((data, key) => (
                                                                <div key={key} className='py-2 mb-2' style={{ borderBottom: '2px solid #e9ebec' }}>
                                                                    <div className="d-flex mb-1">
                                                                        <div className="avatar-xxs">
                                                                            <div className="avatar-title bg-danger-subtle rounded">
                                                                                <i className="bx bxs-compass text-danger"></i>
                                                                            </div>
                                                                        </div>
                                                                        <div className="ms-3 flex-grow-1">
                                                                            <div className='d-flex align-items-center'>
                                                                                <h5 className="job-title fs-12 mb-2 me-3" style={{ cursor: 'pointer', textDecoration: 'underline' }}>{data.cvp.certificationIndex}</h5>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                    <div className='d-flex align-items-center flex-wrap mb-1'>
                                                                        {
                                                                            data.cvp.ccfNameList.map((item, key) => (
                                                                                <div className='bg-light rounded p-0 fs-10 cursor-pointer me-3 mb-1' onClick={() => toggleCCFEvidence(item.id, pid, item.ccf)} key={key}>
                                                                                    <i className="ri-honour-line align-bottom me-0"></i>{" "}
                                                                                    <span className="job-type">
                                                                                        {item.ccf}
                                                                                    </span>
                                                                                </div>
                                                                            ))
                                                                        }
                                                                    </div>

                                                                    <TruncatedText text={data.cvp.controlWording} wordLimit={30} />
                                                                </div>
                                                            ))}
                                                        </CardBody>

                                                    </Card>
                                                </Col>
                                            </Row>
                                        </TabPane>
                                    ))
                                }

                            </TabContent>

                        </Col>
                    </Row>
                </Container>
            </div>

            {/* Activity Canvas */}
            <Offcanvas direction="end" isOpen={isRight} toggle={toggleRightCanvas} id="offcanvasExample">
                <OffcanvasHeader toggle={toggleRightCanvas} id="offcanvasExampleLabel" className="border-bottom py-2" >
                    <div className="fs-13 mb-0">{ccfNameTitle} Acitivity</div>
                </OffcanvasHeader>
                <OffcanvasBody className="p-0 hideScrollbar">
                    <Nav tabs className="nav nav-tabs nav-justified nav-border-top nav-border-top-success mt-3 mb-3">
                        <NavItem>
                            <NavLink style={{ cursor: "pointer", padding: '.2rem', fontSize: '.75rem' }} className={classnames({ active: topBorderTab === "1", })} onClick={() => { topBordertoggle("1"); }} >
                                <i className="ri-home-5-line align-middle me-1"></i> Evidence
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink style={{ cursor: "pointer", padding: '.2rem', fontSize: '.75rem' }} className={classnames({ active: topBorderTab === "2", })} onClick={() => { topBordertoggle("2"); }} >
                                <i className="ri-user-line me-1 align-middle me-1"></i> Activities
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink style={{ cursor: "pointer", padding: '.2rem', fontSize: '.75rem' }} className={classnames({ active: topBorderTab === "3", })} onClick={() => { topBordertoggle("3"); }} >
                                <i className="ri-question-answer-line align-middle me-1"></i> References
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink style={{ cursor: "pointer", padding: '.2rem', fontSize: '.75rem' }} className={classnames({ active: topBorderTab === "4", })} onClick={() => { topBordertoggle("4"); }} >
                                <i className="ri-edit-fill me-1"></i> Edit Status
                            </NavLink>
                        </NavItem>
                    </Nav>

                    <TabContent activeTab={topBorderTab} className="text-muted px-3">

                        <TabPane tabId="1" id="nav-border-justified-home">
                            <h6>Evidence</h6>
                            <Form className='mb-4' onSubmit={uploadEvidence}>
                                <Row>
                                    <Col lg={9}>
                                        <Input
                                            id="exampleFile"
                                            name="file"
                                            type="file"
                                            onChange={handleFileChange}
                                        />
                                    </Col>
                                    <Col lg={3}>
                                        <Button className="btn btn-primary py-1" type="submit">
                                            Upload
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>

                            <ListGroup>

                                {
                                    evidenceFile.map((file, index) => (
                                        <ListGroupItem className='mb-2 border d-flex justify-content-between py-0' key={index}>
                                            <div className="d-flex align-items-center">
                                                <i className="ri-bill-line align-middle text-muted fs-5 me-3"></i>
                                                <div>
                                                    <p className="mb-0 fs-12 text-muted">{(file.file).split('/').pop()}</p>
                                                    <h5 className='fs-11 mb-0'>{file.firstName}</h5>
                                                </div>
                                            </div>
                                            <div className="evd-action d-flex flex-column">

                                                <i className="ri-delete-bin-line cursor-pointer" onClick={() => deleteEvidence(idForDeleteFile, pid, file.file)}></i>
                                                <i className="ri-download-2-line cursor-pointer" onClick={() => downloadEvidence(idForDeleteFile, pid, file.file)}></i>
                                            </div>
                                        </ListGroupItem>
                                    ))
                                }

                            </ListGroup>

                        </TabPane>

                        <TabPane tabId="2" id="nav-border-justified-profile">
                            <h6>Comments</h6>

                            <Nav tabs className="nav-tabs mb-3">

                                {activityCommentsTab.map((tab, index) => (
                                    <NavItem key={index}>
                                        <NavLink
                                            style={{ cursor: "pointer", fontSize: '.7rem' }}
                                            className={classnames({ active: activeTab === (tab).toString() })}
                                            onClick={() => { toggle((tab).toString()); }}
                                        >
                                            {tab}
                                        </NavLink>
                                    </NavItem>
                                ))}

                            </Nav>

                            <TabContent activeTab={activeTab} className="text-muted">
                                {activityCommentsTab.map((tab, index) => (
                                    <TabPane tabId={(tab).toString()} key={index}>
                                        <div className="user-chat w-100 overflow-hidden minimal-border">
                                            <div className="chat-content d-lg-flex">
                                                <div className="w-100 overflow-hidden position-relative">
                                                    <div className="position-relative" id="users-chat">
                                                        <div className="chat-conversation p-3 p-lg-4" id="chat-conversation">
                                                            <PerfectScrollbar>
                                                                <ul className="list-unstyled chat-conversation-list" id="users-conversation">
                                                                    {messages[tab] && messages[tab].length > 0 ? (
                                                                        map(messages[tab], (message, key) => {
                                                                            return (
                                                                                <li
                                                                                    className={message.ssoid === currentUser ? "chat-list right" : "chat-list left"}
                                                                                    key={key}
                                                                                >
                                                                                    <div className="conversation-list">
                                                                                        <div className="user-chat-content">
                                                                                            <div className="ctext-wrap">
                                                                                                <div className="ctext-wrap-content">
                                                                                                    <p className="mb-0 ctext-content">
                                                                                                        {message.comment}
                                                                                                    </p>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="conversation-name">
                                                                                                <small className="text-muted fs-10 time">{message.date}</small>
                                                                                                <span className="text-success check-message-icon">
                                                                                                    <i className="ri-check-double-line align-bottom"></i>
                                                                                                </span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </li>
                                                                            );
                                                                        })
                                                                    ) : (
                                                                        <p>No messages available</p>
                                                                    )}

                                                                </ul>
                                                            </PerfectScrollbar>
                                                        </div>

                                                        <div className="chat-input-section pt-2">
                                                            <form id="chatinput-form" onSubmit={handleSendMessage}>
                                                                <Row className="g-0 align-items-center">
                                                                    <div className="col">
                                                                        <input
                                                                            type="text"
                                                                            className="form-control fs-11 chat-input bg-light border-light"
                                                                            placeholder="Type your message..."
                                                                            value={inputMessage}
                                                                            onChange={handleInputChange}
                                                                        />
                                                                    </div>
                                                                    <div className="col-auto">
                                                                        <div className="chat-input-links ms-2">
                                                                            <div className="links-list-item">
                                                                                <Button
                                                                                    type="submit"
                                                                                    color="success"
                                                                                    className="chat-send waves-effect waves-light"
                                                                                >
                                                                                    <i className="ri-send-plane-2-fill align-bottom"></i>
                                                                                </Button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Row>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </TabPane>
                                ))}
                            </TabContent>

                        </TabPane>

                        <TabPane tabId="3" id="nav-border-justified-messages">
                            <h6> References </h6>
                            <div className='mb-2'>
                                <Alert color="secondary" className="material-shadow py-1 mb-1 fs-12">
                                    Narrative
                                </Alert>
                                <ul className='pe-0 ps-3'>
                                    <li className='justify-text-w-sm-font' style={{ listStyle: 'none' }}>
                                        {referencesData.control_narrative}
                                    </li>
                                </ul>
                            </div>

                            <div className='mb-2'>
                                <Alert color="warning" className="material-shadow py-1 mb-1 fs-12">
                                    Artifacts
                                </Alert>
                                <ul className='pe-0 ps-3'>
                                    <li className='justify-text-w-sm-font' style={{ listStyle: 'none' }}>
                                        {referencesData.control_artifacts}
                                    </li>
                                </ul>
                            </div>

                        </TabPane>
                        <TabPane tabId="4" id="nav-border-justified-home">
                            <h6> Edit Mapped Compliance Status </h6>

                            <div className='mt-3'>
                                <h5>CCF</h5>
                                <UncontrolledDropdown>
                                    <DropdownToggle tag="button" className={`btn btn-sm ${projectStatus === "Active" ? 'btn-secondary' : projectStatus === "Completed" ? 'btn-success' : 'btn-secondary'}`} id="dropdownMenuButton">
                                        Status : {projectStatus} <i className="mdi mdi-chevron-down"></i>
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem onClick={() => setProjectStatus("Active")}>Active</DropdownItem>
                                        <DropdownItem onClick={() => setProjectStatus("Completed")}>Completed</DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                            </div>
                        </TabPane>

                    </TabContent>

                </OffcanvasBody>
            </Offcanvas>

            {/* Compliance Canvas */}
            <ComplianceCanvas
                complianceOffCanvas={complianceOffCanvas}
                toggleComplianceOffCanvas={toggleComplianceOffCanvas}
                mappedComplianceName={mappedComplianceName}
                mappedPointsData={mappedPointsData}
            />
        </>
    )
}

export default Testing;