import React from 'react'
import { Spinner } from 'reactstrap'

export default function DataLoading() {
    return (
        <>
            <div className='dataLoading'>
                <Spinner
                    color="primary"
                    style={{
                        height: '3rem',
                        width: '3rem'
                    }}
                >
                </Spinner>
            </div>
        </>
    )
}
