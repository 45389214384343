import classnames from 'classnames';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Col, Dropdown, DropdownMenu, DropdownToggle, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';

//import images

//SimpleBar
import SimpleBar from "simplebar-react";
import { getNotificationsAPI } from '../../apiConfig/apiCall';

const NotificationDropdown = () => {
    //Dropdown Toggle
    const [isNotificationDropdown, setIsNotificationDropdown] = useState(false);
    const toggleNotificationDropdown = () => {
        setIsNotificationDropdown(!isNotificationDropdown);
    };

    //Tab 
    const [activeTab, setActiveTab] = useState('All');
    const toggleTab = (tab) => {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    };

    const [notificationAll, setNotificationAll] = useState([]);
    const [notifications, setNotifications] = useState([]);
    const [notificationAlert, setNotificationAlert] = useState([]);

    const getNotification = async () => {
        try {
            const data = {
                type: '',
                notificationFor: '',
                unread: '',
                entryDate: ''
            }
            const apiResponse = await getNotificationsAPI(data);
            if (apiResponse.status === 200) {
                setNotificationAll(apiResponse.data);
                setNotifications(
                    apiResponse.data.filter((item) => item.type === "Notification")
                );   
                setNotificationAlert(
                    apiResponse.data.filter((item) => item.type === "Alert")
                );   
            }

            else {
                toast.error(apiResponse.data);
            }
        } catch (error) {
            console.log(error.message);
        }
    }

    useEffect(() => {
        getNotification();
    }, [])

    return (
        <React.Fragment>
            <Dropdown isOpen={isNotificationDropdown} toggle={toggleNotificationDropdown} className="topbar-head-dropdown ms-1 header-item">
                <DropdownToggle type="button" tag="button" className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle">
                    <i className='bx bx-bell fs-22'></i>
                    <span
                        className="position-absolute topbar-badge fs-10 translate-middle badge rounded-pill bg-danger">3<span
                            className="visually-hidden">unread messages</span></span>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-lg dropdown-menu-end p-0">
                    <div className="dropdown-head bg-primary bg-pattern rounded-top">
                        <div className="p-3">
                            <Row className="align-items-center">
                                <Col>
                                    <h6 className="m-0 fs-16 fw-semibold text-white"> Notifications </h6>
                                </Col>
                                <div className="col-auto dropdown-tabs">
                                    <span className="badge bg-light-subtle text-body fs-13"> 4 New</span>
                                </div>
                            </Row>
                        </div>

                        <div className="px-2 pt-2">
                            <Nav className="nav-tabs dropdown-tabs nav-tabs-custom">

                                <NavItem>
                                    <NavLink
                                        href="#"
                                        className={classnames({ active: activeTab === 'All' })}
                                        onClick={() => { toggleTab('All'); }}
                                    >
                                        All
                                    </NavLink>
                                </NavItem>

                                <NavItem>
                                    <NavLink
                                        href="#"
                                        className={classnames({ active: activeTab === 'Notification' })}
                                        onClick={() => { toggleTab('Notification'); }}
                                    >
                                        Notification
                                    </NavLink>
                                </NavItem>

                                <NavItem>
                                    <NavLink
                                        href="#"
                                        className={classnames({ active: activeTab === 'Alert' })}
                                        onClick={() => { toggleTab('Alert'); }}
                                    >
                                        Alert
                                    </NavLink>
                                </NavItem>

                               
                            </Nav>
                        </div>

                    </div>

                    <TabContent activeTab={activeTab}>

                        <TabPane tabId="All" className="py-2 ps-2">
                            <SimpleBar style={{ maxHeight: "300px" }} className="pe-2">


                                {
                                    notificationAll.slice(0, 5).map((item, index) => (
                                        <div className="text-reset notification-item d-block dropdown-item position-relative" key={index}>
                                            <div className="d-flex">
                                                <div className="avatar-xs me-3">
                                                    <span className="avatar-title bg-info-subtle text-info rounded-circle fs-16">
                                                        <i className="bx bx-badge-check"></i>
                                                    </span>
                                                </div>
                                                <div className="flex-grow-1">
                                                    <Link to="#" className="stretched-link">
                                                        <h6 className="mt-0 mb-2 lh-base">
                                                            {item.overView}
                                                        </h6>
                                                    </Link>
                                                    <p className="mb-0 fs-11 fw-medium text-uppercase text-muted">
                                                        <span>
                                                            <i className="mdi mdi-clock-outline"></i>{' '}
                                                            {new Date(item.entryDate).toLocaleString('en-IN', {
                                                                dateStyle: 'short',
                                                                timeStyle: 'short',
                                                            })}
                                                        </span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }

                                <div className="my-3 text-center">
                                    <Link to={'/notifications'} className="btn btn-soft-success waves-effect waves-light">View All Notifications <i className="ri-arrow-right-line align-middle"></i></Link>
                                </div>

                            </SimpleBar>

                        </TabPane>

                        <TabPane tabId="Notification" className="py-2 ps-2">
                            <SimpleBar style={{ maxHeight: "300px" }} className="pe-2">


                                {
                                    notifications.slice(0, 5).map((item, index) => (
                                        <div className="text-reset notification-item d-block dropdown-item position-relative" key={index}>
                                            <div className="d-flex">
                                                <div className="avatar-xs me-3">
                                                    <span className="avatar-title bg-success-subtle text-info rounded-circle fs-16">
                                                        <i className="bx bx-bell text-success"></i>
                                                    </span>
                                                </div>
                                                <div className="flex-grow-1">
                                                    <Link to="#" className="stretched-link">
                                                        <h6 className="mt-0 mb-2 lh-base">
                                                            {item.overView}
                                                        </h6>
                                                    </Link>
                                                    <p className="mb-0 fs-11 fw-medium text-uppercase text-muted">
                                                        <span>
                                                            <i className="mdi mdi-clock-outline"></i>{' '}
                                                            {new Date(item.entryDate).toLocaleString('en-IN', {
                                                                dateStyle: 'short',
                                                                timeStyle: 'short',
                                                            })}
                                                        </span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }

                                <div className="my-3 text-center">
                                    <Link to={'/notifications'} className="btn btn-soft-success waves-effect waves-light">View All Notifications <i className="ri-arrow-right-line align-middle"></i></Link>
                                </div>

                            </SimpleBar>

                        </TabPane>

                        <TabPane tabId="Alert" className="py-2 ps-2">
                            <SimpleBar style={{ maxHeight: "300px" }} className="pe-2">


                                {
                                    notificationAlert.slice(0, 5).map((item, index) => (
                                        <div className="text-reset notification-item d-block dropdown-item position-relative" key={index}>
                                            <div className="d-flex">
                                                <div className="avatar-xs me-3">
                                                    <span className="avatar-title bg-danger-subtle text-info rounded-circle fs-16">
                                                        <i className="bx bx-error-circle text-danger"></i>
                                                    </span>
                                                </div>
                                                <div className="flex-grow-1">
                                                    <Link to="#" className="stretched-link">
                                                        <h6 className="mt-0 mb-2 lh-base">
                                                            {item.overView}
                                                        </h6>
                                                    </Link>
                                                    <p className="mb-0 fs-11 fw-medium text-uppercase text-muted">
                                                        <span>
                                                            <i className="mdi mdi-clock-outline"></i>{' '}
                                                            {new Date(item.entryDate).toLocaleString('en-IN', {
                                                                dateStyle: 'short',
                                                                timeStyle: 'short',
                                                            })}
                                                        </span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }

                                <div className="my-3 text-center">
                                    <Link to={'/notifications'} className="btn btn-soft-success waves-effect waves-light">View All Notifications <i className="ri-arrow-right-line align-middle"></i></Link>
                                </div>

                            </SimpleBar>

                        </TabPane>

                    </TabContent>
                </DropdownMenu>
            </Dropdown>
        </React.Fragment>
    );
};

export default NotificationDropdown;