import React from "react";
import { Navigate } from "react-router-dom";

import Dashboard from "../pages/Dashboard";
import Login from "../pages/Authentication/Login";
import Alt404 from '../pages/AuthenticationInner/Errors/Alt404';
import CompanyDetails from "../pages/CompanyDetails/CompanyDetails";
import UsersList from "../pages/ManageUsers/UsersList";
import AuditorsList from "../pages/ManageAuditors/AuditorsList";
import Projects from "../pages/Projects";
import PoliciesList from "../pages/ManagePolicy/PoliciesList";
import AssetsList from "../pages/ManageAssets/AssetsList";
import Testing from "../pages/Testing/Testing";
import ProjectTask from "../pages/Projects/ProjectTask";
import ManageNotifications from '../pages/ManageNotifications'
import EvidenceView from "../pages/EvidenceView";

const authProtectedRoutes = [
  { path: "/dashboard", component: <Dashboard /> },
  { path: "/", exact: true, component: <Navigate to="/dashboard" /> },
  { path: "/company-details", component: <CompanyDetails /> },
  { path: "/users-list", component: <UsersList /> },
  { path: "/auditors-list", component: <AuditorsList /> },
  { path: "/projects", component: <Projects /> },
  { path: "/project-task/:id", component: <ProjectTask /> },
  { path: "/policies-list", component: <PoliciesList /> },
  { path: "/evidence-view", component: <EvidenceView /> },
  { path: "/assets-list", component: <AssetsList /> },
  { path: "/notifications", component: <ManageNotifications /> },
  { path: "*", component: <Navigate to="/not-found" /> },

  { path: "/test", component: <Testing /> },
];

const publicRoutes = [
  { path: "/login", component: <Login /> },
  { path: "/not-found", component: <Alt404 /> },
];

export { authProtectedRoutes, publicRoutes };