import { combineReducers } from "redux";

import LayoutReducer from "./layouts/reducer";
import dashboardReducer from "../slices/dashboard/dashboardSlice";
import UserDetailsReducer from "./UserDeatils/dashboardWidgetDataSlice";
import CompanyDetailsReducer from "./CompanyDetails/getCompanyDetailsSlice";
import EvidenceViewReducer from "./EvidenceView/getEvidenceSlice";

const rootReducer = combineReducers({
    Layout: LayoutReducer,
    Dashboard: dashboardReducer,
    UserDetails: UserDetailsReducer,
    CompanyDetails: CompanyDetailsReducer,
    EvidenceView: EvidenceViewReducer,
});

export default rootReducer;