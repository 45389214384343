import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  dashboardTotalUsersAndAuditors, dashboardTotalPolicies, dashboardTotalAssets, dashboardOverallCompliance, dashboardEvidenceStatus, dashboardCertificationCompliance, dashboardDepartmentCompliance, dashboardActiveUsers,
} from "../../apiConfig/apiCall";

const initialState = {
  loading: false,
  widgetData: {
    usersCount: null,
    auditorsCount: null,
    policiesCount: null,
    assetsCount: null,
  },
  data: {
    overallCompliance: [],
    evidenceStatus: [],
    certificationCompliance: [],
    departmentCompliance: [],
    activeCompliance: [],
    activeUsers: [],
  },
  error: null,
};

export const fetchDashboardData = createAsyncThunk('dashboard/fetchData', async (CLIENT_ID) => {
  const [
    usersResponse,
    auditorsResponse,
    policiesResponse,
    assetsResponse,
    overallComplianceResponse,
    evidenceStatusResponse,
    certificationComplianceResponse,
    departmentComplianceResponse,
    activeComplianceResponse,
    activeUsersResponse
  ] = await Promise.all([
    dashboardTotalUsersAndAuditors('CLIENT_USER'),
    dashboardTotalUsersAndAuditors('AUDITOR'),
    dashboardTotalPolicies(CLIENT_ID),
    dashboardTotalAssets(CLIENT_ID),
    dashboardOverallCompliance(CLIENT_ID),
    dashboardEvidenceStatus(CLIENT_ID),
    dashboardCertificationCompliance(CLIENT_ID),
    dashboardDepartmentCompliance(CLIENT_ID),
    dashboardCertificationCompliance(CLIENT_ID),
    dashboardActiveUsers(CLIENT_ID)
  ]);

  return {
    widgetData: {
      usersCount: usersResponse.status === 200 ? usersResponse.data : null,
      auditorsCount: auditorsResponse.status === 200 ? auditorsResponse.data : null,
      policiesCount: policiesResponse.status === 200 ? policiesResponse.data : null,
      assetsCount: assetsResponse.status === 200 ? assetsResponse.data : null,
    },
    overallCompliance: overallComplianceResponse.status === 200 ? overallComplianceResponse.data : [],
    evidenceStatus: evidenceStatusResponse.status === 200 ? evidenceStatusResponse.data : [],
    certificationCompliance: certificationComplianceResponse.status === 200 ? certificationComplianceResponse.data : [],
    departmentCompliance: departmentComplianceResponse.status === 200 ? departmentComplianceResponse.data : [],
    activeCompliance: activeComplianceResponse.status === 200 ? activeComplianceResponse.data : [],
    activeUsers: activeUsersResponse.status === 200 ? activeUsersResponse.data : [],
  };
});

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchDashboardData.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchDashboardData.fulfilled, (state, action) => {
        state.loading = false;
        state.widgetData = action.payload.widgetData;
        state.data = {
          overallCompliance: action.payload.overallCompliance,
          evidenceStatus: action.payload.evidenceStatus,
          certificationCompliance: action.payload.certificationCompliance,
          departmentCompliance: action.payload.departmentCompliance,
          activeCompliance: action.payload.activeCompliance,
          activeUsers: action.payload.activeUsers,
        };
      })
      .addCase(fetchDashboardData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default dashboardSlice.reducer;