import React, { useRef, useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap';
import 'react-image-crop/dist/ReactCrop.css'
import ImageCropper from './ImageCropper';
import dummyUserImage from '../../assets/images/users/user-dummy-img.jpg';

const ProfileSelector = ({ image, ssoId, modalHandler }) => {

    const [isModalOpen, setIsModalOpen] = useState(false);
    const toggleModal = () => setIsModalOpen(!isModalOpen);
    const closeModal = () => setIsModalOpen(false);

    const avatarUrl = useRef( image);

      const updateAvatar = (imgSrc) => {
        avatarUrl.current = imgSrc;
      };

    return (
        <>
            <div className="profile-select d-flex justify-content-center align-items-center">
                <img src={avatarUrl.current} onError={(e) => { e.target.src = dummyUserImage }} className="avatar-md rounded-circle" alt="..." />
                <div className="w-25 ms-3">
                    <Button color="primary" onClick={toggleModal}>
                        Select Profile
                    </Button>
                </div>
            </div>

            <Modal isOpen={isModalOpen} toggle={toggleModal}>
                <ModalHeader toggle={toggleModal}>Choose Profile Image</ModalHeader>
                <ModalBody>
                    <ImageCropper updateAvatar={updateAvatar} closeModal={closeModal} ssoId={ssoId} modalHandler={modalHandler}/>
                </ModalBody>
            </Modal>
        </>
    );
};

export default ProfileSelector;