export default function Loading() {

    return (
        <div>
            <div className="animation">
                <div className="loading-container">
                    <p id="loading-text">Loading....</p>
                </div>
            </div>
        </div>
    )
}
