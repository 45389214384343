import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Card, CardBody, CardHeader, Col, Input, Modal, ModalBody, Row, Spinner, TabPane, UncontrolledCollapse } from 'reactstrap';
import SimpleBar from 'simplebar-react';
import { downloadAllEvidenceAPI, getCCFControlsApi, getProjectTaskWithCCF } from '../../../apiConfig/apiCall';

export default function CCFData({ defaultComplianceTabName, toggleEvidence, toggleMappedPointWithCCF }) {

    const CLIENT_ID = Cookies.get('CLIENT_ID');

    // All States
    const role = Cookies.get('role');
    const userSSOid = Cookies.get('user');

    const [loading, setLoading] = useState(false);
    const [originalCcfData, setOriginalCcfData] = useState([]);
    const [ccfData, setCcfData] = useState([]);
    const [pid, setPid] = useState('');
    const [filteredData, setFilteredData] = useState(ccfData);
    const [activeFilter, setActiveFilter] = useState('All');
    const [searchQuery, setSearchQuery] = useState('');
    const [ccfControls, setCcfControls] = useState({});
    const [openDropdownForCCFControls, setOpenDropdownForCCFControls] = useState(null);

    // Handle Pagination Logic For CCF Start
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 5;
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;


    const filteredCCFData = filteredData.filter(data => {
        const searchQueryLower = searchQuery.toLowerCase();
        const ccfPoints = data.certificationVersionCCFPoints;

        return (
            data.controlReference.toLowerCase().includes(searchQueryLower) ||
            ccfPoints.controlArtifacts.toLowerCase().includes(searchQueryLower) ||
            ccfPoints.controlNarrative.toLowerCase().includes(searchQueryLower) ||
            ccfPoints.controlTitle.toLowerCase().includes(searchQueryLower) ||
            ccfPoints.controlType.toLowerCase().includes(searchQueryLower) ||
            ccfPoints.controlWording.toLowerCase().includes(searchQueryLower) ||
            ccfPoints.department.toLowerCase().includes(searchQueryLower) ||
            ccfPoints.domainTitle.toLowerCase().includes(searchQueryLower)
        );
    });


    const paginateCCFData = filteredCCFData.slice(indexOfFirstItem, indexOfLastItem);

    const handleNextPage = () => {
        if (currentPage < Math.ceil(ccfData.length / itemsPerPage)) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };
    // Handle Pagination Logic For CCF End

    // Toggle CCF Controls Start
    const toggleDropdownForCCFControls = (id) => {
        setOpenDropdownForCCFControls(openDropdownForCCFControls === id ? null : id);
    };
    // Toggle CCF Controls End

    // Handle Filter CCF From Controls Start
    const [currentCCFFilterControlApply, setCurrentCCFFilterControlApply] = useState('');
    const handleFilterCCFFromControls = async (controlValue, controlTitle) => {
        try {
            setLoading(true); 
            setCurrentPage(1);
            setCurrentCCFFilterControlApply(controlValue.version);
    
            if (controlValue === "CCF") {
                setTimeout(() => {
                    setCcfData(originalCcfData); 
                    setCurrentCCFFilterControlApply('All CCF');
                    setLoading(false); 
                }, 500); 
            } else {
                setTimeout(() => {
                    const filteredCCF = originalCcfData.filter(data =>
                        data.certificationVersionCCFPoints.department === controlValue.version ||
                        data.certificationVersionCCFPoints.domainTitle === controlValue.version ||
                        data.certificationVersionCCFPoints.controlType === controlValue.version
                    );
                    setCcfData(filteredCCF);
                    setLoading(false); 
                }, 500);
            }
        } catch (error) {
            console.log(error);
            setLoading(false); 
        }
    };
    // Handle Filter CCF From Controls End

    // Get CCF Data Api Logic Start
    const getProjectTaskDetailsCompliance = async (CLIENT_ID) => {
        try {
            const apiResponse = await getProjectTaskWithCCF(CLIENT_ID);
            if (apiResponse.status === 200) {
                setPid(apiResponse.data.id);
                setOriginalCcfData(apiResponse.data.requirementApproach);
                if (role === "CLIENT_ADMIN" || role === "AUDITOR" || userSSOid === apiResponse.data.projectManager) {
                    const filteredData = apiResponse.data.requirementApproach;
                    setCcfData(filteredData);
                } else if (role === "CLIENT_USER") {
                    const filteredData = apiResponse.data.requirementApproach.filter(data => data.user === userSSOid);
                    setCcfData(filteredData);
                }
            }
        } catch (error) {
            console.log(error);
        }
    }
    // Get CCF Data Api Logic Start

    // Get CCF Control Api Logic Start
    const getCCFControls = async () => {
        try {
            const apiResponse = await getCCFControlsApi();
            if (apiResponse.status === 200) {
                setCcfControls(apiResponse.data);
            }
        } catch (error) {
            console.log(error);
        }
    }
    // Get CCF Control Api Logic End


    //  Filter CCF All Inscope Outscope Logic Start
    const handleFilterClick = (filter) => {
        setLoading(true);
        setActiveFilter(filter);
        setCurrentPage(1);
        if (filter === 'All') {
            setFilteredData(ccfData);
            setLoading(false);
        } else if (filter === 'In-Scope') {
            setFilteredData(ccfData.filter(item => item.applicable === true));
            setLoading(false);
        } else if (filter === 'Out-Scope') {
            setFilteredData(ccfData.filter(item => item.applicable === false));
            setLoading(false);
        }
    };
    //  Filter CCF All Inscope Outscope Logic End

    // Search CCF Logic Start
    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
        setCurrentPage(1);
    };
    // Search CCF Logic End

    const transformDataToProjects = (data) => {
        const bgColors = ['danger', 'info', 'warning', 'success'];
        return Object.keys(data).map((key, index) => ({
            id: index + 1,
            bg: bgColors[index % bgColors.length],
            subItem: data[key]
                .filter(item => item)
                .map((version, subIndex) => ({
                    iconClass: bgColors[index % bgColors.length],
                    id: subIndex + 1,
                    version: version
                })),
            title: key.replace(/([a-z])([A-Z])/g, '$1 $2')
        }));
    };

    const ccfControlsValue = transformDataToProjects(ccfControls);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            await getProjectTaskDetailsCompliance(CLIENT_ID);
            await getCCFControls();
            setLoading(false);
        };
        fetchData();
    }, []);

    useEffect(() => {
        setFilteredData(ccfData);
    }, [ccfData]);



    const TruncatedText = ({ text, wordLimit = 50 }) => {
        const words = text.split(' ');
        const truncatedText = words.slice(0, wordLimit).join(' ');
        const [isExpanded, setIsExpanded] = useState(false);

        return (
            <div>
                <div className="text-muted job-description" style={{ fontSize: '.7rem', textAlign: 'justify' }}>
                    {isExpanded ? text : `${truncatedText}${words.length > wordLimit ? '...' : ''}`}
                    {words.length > wordLimit && (
                        <button onClick={() => setIsExpanded(!isExpanded)} style={{ border: 'none', background: 'none', color: '#007bff', cursor: 'pointer', padding: '0' }}>
                            {isExpanded ? 'Read Less' : 'Read More'}
                        </button>
                    )}
                </div>
            </div>
        );
    };

    const [downloadEvidenceModal, setDownloadEvidenceModal] = useState(false);
    const downloadEvidenceModalPopup = () => setDownloadEvidenceModal(!downloadEvidenceModal);
    const downloadEvidenceOfOneCCF = async () => {

        downloadEvidenceModalPopup();

        const ccfName = "ALL";

        const apiResponse = await downloadAllEvidenceAPI(pid, ccfName, 'blob');
        if (apiResponse.status === 200) {
            setDownloadEvidenceModal(false);
            const blob = new Blob([apiResponse.data], { type: 'application/zip' });
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${ccfName || 'evidence'}.zip`);
            document.body.appendChild(link);
            link.click();
            window.URL.revokeObjectURL(url);
            link.remove();
        }

    }

    return (
        <>

            <TabPane tabId={defaultComplianceTabName.title}>
                <Row>
                    <Col xxl={2} xl={3} lg={3} className='pe-1'>
                        <Card>
                            <CardBody className='projectTaskCCFDataControls p-2'>
                                <SimpleBar className="px-4 mx-n4">
                                    <ul className="to-do-menu list-unstyled" id="projectlist-data">
                                        <li>
                                            <Link
                                                to="#"
                                                className="nav-link fs-11 d-flex align-items-center justify-content-between"
                                                onClick={() => handleFilterCCFFromControls(defaultComplianceTabName.title)}>
                                                <div className='d-flex'>
                                                    <span className={"icon me-2 bg-" + 'danger' + "-subtle" + " text-" + 'danger'}>
                                                        <i className='bx bxs-compass'></i>
                                                    </span>
                                                    All Control
                                                </div>
                                                <i className='ri-arrow-right-s-line'></i>
                                            </Link>
                                        </li>
                                        {ccfControlsValue
                                            .filter((item) => item.title !== "Control Title")
                                            .map((item) => (
                                                <li key={item.id}>
                                                    <Link
                                                        to="#"
                                                        className="nav-link fs-11 d-flex align-items-center"
                                                        id={"todos" + item.id}
                                                        onClick={() => toggleDropdownForCCFControls(item.id)}
                                                    >
                                                        <div className='d-flex justify-content-between w-100'>
                                                            <div className='d-flex'>
                                                                <span className={"icon me-2 bg-" + item.bg + "-subtle" + " text-" + item.bg}>
                                                                    <i className='bx bxs-compass'></i>
                                                                </span>
                                                                {item.title}
                                                            </div>
                                                            <i className={openDropdownForCCFControls === item.id ? "ri-arrow-down-s-line" : "ri-arrow-right-s-line"}></i>
                                                        </div>
                                                    </Link>
                                                    <UncontrolledCollapse isOpen={openDropdownForCCFControls === item.id} toggler={"#todos" + item.id}>
                                                        <ul className="mb-0 sub-menu control-list list-unstyled list-unstyled ps-1 vstack gap-2 mb-2">
                                                            {item.subItem.map((subItem) => (
                                                                <li key={subItem.id}>
                                                                    <Link
                                                                        to="#"
                                                                        style={{ fontSize: '.7rem', display: 'flex', position: 'relative' }}
                                                                        onClick={() => handleFilterCCFFromControls(subItem, item.title)}
                                                                    >
                                                                        <i className={"ri-stop-mini-fill align-middle fs-10 me-1 text-" + subItem.iconClass} style={{ position: 'absolute', top: '0.05rem' }}></i>
                                                                        <div className='ps-3'>{subItem.version}</div>
                                                                    </Link>
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    </UncontrolledCollapse>
                                                </li>
                                            ))}
                                    </ul>
                                </SimpleBar>
                            </CardBody>
                        </Card>
                    </Col>

                    <Col xxl={10} xl={9} lg={9} className='ps-1 pe-1'>
                        <Card>

                            <CardBody>
                                <CardHeader className='p-0 pb-2 d-flex align-items-start justify-content-between'>

                                    <div className='d-flex align-items-start'>

                                        <div className="search app-search p-0">
                                            <div className="position-relative">
                                                <Input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Search..."
                                                    value={searchQuery}
                                                    onChange={handleSearchChange}
                                                />
                                                <span className="mdi mdi-magnify search-widget-icon"></span>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="d-flex align-items-center mb-2 cursor-pointer float-end" onClick={downloadEvidenceOfOneCCF}>
                                        <i className="ri-download-line text-dark me-2" style={{ fontSize: '16px' }}></i>
                                        <p className='mb-0' style={{ textDecoration: 'underline', color: '#000', display: 'flex', alignItems: 'center', gap: '8px' }}>
                                            Download all ccf evidence
                                        </p>
                                    </div>

                                </CardHeader>

                                <div className='projectTaskCCFData'>

                                    {loading ? (
                                        <div className="text-center" style={{ height: '30vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                            <div>
                                                <Spinner color="primary" style={{ opacity: '.2' }} />
                                                <p style={{ opacity: '.4' }}>Loading Data...</p>
                                            </div>
                                        </div>
                                    ) : (
                                        (paginateCCFData && paginateCCFData.length > 0) ? (
                                            paginateCCFData
                                                .map((data, key) => (
                                                    <div key={key} className='py-2 mb-2' style={{ borderBottom: '2px solid #e9ebec', position: 'relative' }}>
                                                        <div className="d-flex mb-1">
                                                            <div className="avatar-xxs">
                                                                <div className="avatar-title bg-danger-subtle rounded">
                                                                    <i className="bx bxs-compass text-danger"></i>
                                                                </div>
                                                            </div>
                                                            <div className="ms-3 flex-grow-1">
                                                                <div className='d-flex align-items-center'>
                                                                    <h5 className="job-title fs-12 mb-0 me-3" onClick={() => toggleEvidence(data.id, pid, data.controlReference)} style={{ cursor: 'pointer', textDecoration: 'underline' }}>{data.controlReference}</h5>
                                                                    <div className='d-flex align-items-center flex-wrap gap-3'>
                                                                        {console.log(data.certificationStatus)}
                                                                        {
                                                                            data.certificationStatus
                                                                                .filter((item) => Object.keys(item)[0] !== "CCF")
                                                                                .map((item, index) => {
                                                                                    const title = Object.keys(item)[0];
                                                                                    const status = item[title].status;
                                                                                    return (
                                                                                        <span
                                                                                            className={`cursor-pointer badge ${status === "PROCESSING"
                                                                                                ? "bg-warning-subtle text-warning"
                                                                                                : status === "PENDING"
                                                                                                    ? "bg-info-subtle text-info"
                                                                                                    : status === "COMPLETED"
                                                                                                        ? "bg-success-subtle text-success"
                                                                                                        : status === "REJECTED"
                                                                                                            ? "bg-danger-subtle text-danger"
                                                                                                            : "bg-light-subtle text-light"
                                                                                                }`}
                                                                                            key={index}
                                                                                            onClick={() => toggleMappedPointWithCCF(data.controlReference, title)}
                                                                                        >
                                                                                            <i className="ri-honour-line align-bottom me-1"></i>
                                                                                            {title}
                                                                                        </span>
                                                                                    );
                                                                                })
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div
                                                            className={`cursor-pointer badge ${data.status === "PROGRESSING"
                                                                ? "bg-warning text-white"
                                                                : data.status === "PENDING"
                                                                    ? "bg-info text-white"
                                                                    : data.status === "COMPLETED"
                                                                        ? "bg-success text-white"
                                                                        : data.status === "REJECTED"
                                                                            ? "bg-danger text-white"
                                                                            : "bg-dark text-white"
                                                                }`}
                                                            style={{ position: 'absolute', right: '0', top: '0', fontSize: '.6rem' }}>{data.status}</div>

                                                        <p className='mb-0 fs-12 text-success'>Department - {data.certificationVersionCCFPoints.department}</p>

                                                        <TruncatedText text={data.certificationVersionCCFPoints.controlWording} wordLimit={30} />
                                                    </div>
                                                ))
                                        ) : (
                                            <div className="text-center py-3">
                                                <h5>No Data Available</h5>
                                            </div>
                                        )
                                    )}

                                </div>

                            </CardBody>
                            <div className='d-flex justify-content-between align-items-center' style={{ borderTop: '1px solid #ccc', padding: '.5rem' }}>
                                <p className="mb-0 ps-3">Current control is : <span className="fw-bold">{currentCCFFilterControlApply === "" ? "All Control" : currentCCFFilterControlApply}</span></p>
                                {/* Pagination controls */}
                                <div className="pagination-controls">
                                    <Button color='primary' size='sm' onClick={handlePreviousPage} disabled={currentPage === 1}>
                                        Previous
                                    </Button>
                                    <span> Page {currentPage} of {Math.max(1, Math.ceil(filteredCCFData.length / itemsPerPage))} </span>
                                    <Button color='primary' size='sm' onClick={handleNextPage} disabled={currentPage >= Math.ceil(filteredData.length / itemsPerPage)}>
                                        Next
                                    </Button>
                                </div>
                                <p className="mb-0 pe-3">Showing <span className="fw-bold">{filteredCCFData.length}</span> CCF</p>
                            </div>
                        </Card>
                    </Col>
                </Row>
            </TabPane>

            <Modal isOpen={downloadEvidenceModal} toggle={downloadEvidenceModalPopup} backdrop="static" centered>
                <ModalBody>
                    <div className="text-center">
                        <h4>Your file is downloading</h4>
                        <p className="text-muted mx-4 mb-2">
                            File downloading in progreess please wait
                        </p>
                        <p className="text-danger mb-4">Don't reload or refresh the window</p>
                        <Spinner>
                            Loading...
                        </Spinner>
                    </div>
                </ModalBody>
            </Modal>
        </>
    )
}
