import React from 'react'
import { Offcanvas, OffcanvasBody, OffcanvasHeader } from 'reactstrap'

export default function ComplianceCanvas({
    complianceOffCanvas,
    toggleComplianceOffCanvas,
    mappedComplianceName,
    mappedPointsData
}) {

    return (
        <>
            <Offcanvas direction="end" isOpen={complianceOffCanvas} toggle={toggleComplianceOffCanvas} id="offcanvasExample">
                <OffcanvasHeader id="offcanvasExampleLabel" className="border-bottom py-2 bg-primary flexOffcanvasHeader" >
                    <div className="d-flex justify-content-between">
                        <div className="fs-13 mb-0 text-white">{mappedComplianceName}</div>
                        <div className='close-icon cursor-pointer' onClick={toggleComplianceOffCanvas}>
                            <i className='bx bx-x'></i>
                        </div>
                    </div>
                </OffcanvasHeader>
                <OffcanvasBody className="p-3 hideScrollbar">
                    <h6>Mapped Points</h6>
                    <div className="points">
                        {mappedPointsData.length > 0 ? (
                            mappedPointsData.map((data, index) => (
                                <div className="point d-flex mb-2" key={index}>
                                    <div className="icon me-2">
                                        <i className='bx bxs-circle' style={{ fontSize: '.5rem' }}></i>
                                    </div>
                                    <div className="data">
                                        <p className='mb-0 text-justify' style={{ fontSize: '.7rem' }}>
                                            <strong>{data.certificationIndex}: </strong> {data.controlWording}
                                        </p>
                                        <div className="mapped-ccf mt-1">
                                            {data.ccfNameList.map((ccfData, ccfIndex) => (
                                                <span
                                                    className="badge bg-info-subtle text-info badge-border me-2 mb-2"
                                                    style={{ fontSize: '.65rem' }}
                                                    key={ccfIndex}
                                                >
                                                    {ccfData.ccf}
                                                </span>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <p>No points are mapped</p>
                        )}
                    </div>
                </OffcanvasBody>
            </Offcanvas>
        </>
    )
}
