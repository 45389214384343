import React, { useEffect } from 'react';
import './assets/scss/themes.scss';
import Route from './Routes';
import Toaster from './Components/Toaster'
import { saveQueryParamsToCookies } from './utils/saveQueryParamsToCookies';

function App() {

  useEffect(() => {
    saveQueryParamsToCookies();
  }, []);

  return (
    <React.Fragment>
      <Toaster />
      <Route />
    </React.Fragment>
  );
}

export default App;