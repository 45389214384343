import React, { useEffect } from 'react';
import { Col, Container, Row } from 'reactstrap';
import BreadCrumb from '../../Components/Common/BreadCrumb';
import EvidenceTable from '../../Components/EvidenceView/EvidenceTable';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { getEvidenceViewList } from '../../slices/EvidenceView/thunk';
import Loader from '../../Components/Common/Loader';
import Error from '../../Components/Common/Error';

const EvidenceView = () => {

    const FILTER_DATE = 'creationtime'

    const dispatch = useDispatch();
    const { isLoading, isError, data } = useSelector(state => state.EvidenceView);

    useEffect(() => {
        dispatch(getEvidenceViewList(FILTER_DATE));
    }, [dispatch,]);

    if (isLoading) return <Loader />
    if (isError) return <Error />

    const evidenceData = data;

    return (
        <>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title={'Evidence View'} pageTitle={'Dashboard'} />
                    <Row>
                        <Col lg={12}>
                            <EvidenceTable evidenceData={evidenceData} />
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default EvidenceView