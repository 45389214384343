import { createSlice } from "@reduxjs/toolkit";
import { getCompanyDetailsData } from "./thunk";

const getCompanyDetailsSlice = createSlice({
    name: "getCompanyDetails",
    initialState: {
        isLoading: false,
        isError: false,
        companyDetailsData: [],
        assignedCEO: [],
        contactName: []
    },
    extraReducers: (builder) => {
        builder.addCase(getCompanyDetailsData.pending, (state) => {
            state.isLoading = true;
            state.isError = false;
        });
        builder.addCase(getCompanyDetailsData.fulfilled, (state, action) => {
            state.isLoading = false;
            state.companyDetailsData = action.payload.clientDetails;
            state.assignedCEO = action.payload.ceo;
            state.contactName = action.payload.contactPerson;
        });
        builder.addCase(getCompanyDetailsData.rejected, (state) => {
            state.isError = true;
            state.isLoading = false;
        });

    }
});

export default getCompanyDetailsSlice.reducer;